import React, {Fragment} from "react";
import "./styles.scss";
import IconCard from "../icon-card";
import Requisitos from "../requisitos";
import cards from "../../config/cards";
import CalculadoraPrestamo from "../calculadora-prestamo";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const Home = () => {
  const renderCards = () => cards.map((card, index) => <Fragment key={index}><IconCard {...card} /></Fragment>);
 
    return (
      <div className="container-main">
        <div className="container-left">
          <Typography 
            variant="h1" 
            align="center" 
            gutterBottom
          >
            Destínalo a lo que prefieras
          </Typography>
            <Box
              display="flex"
              flexWrap="wrap"
              height="100%"
              width="100%"
              justifyContent='center'
            >
              {renderCards()}
            </Box>
        </div>
        <div className="container-right">
          <Requisitos />
          <CalculadoraPrestamo/>
        </div>
      </div>
    );
  
}

export default Home;




// import React, {Fragment} from "react";
// import "./styles.scss";
// import IconCard from "../icon-card";
// import Requisitos from "../requisitos";
// import cards from "../../config/cards";
// import CalculadoraPrestamo from "../calculadora-prestamo";
// import Box from '@material-ui/core/Box';
// import Typography from '@material-ui/core/Typography';
// import Grid from '@material-ui/core/Grid';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
// }));


// const Home = () => {
//   const classes = useStyles();
//   const renderCards = () => cards.map((card, index) => <Fragment key={index}><IconCard {...card} /></Fragment>);
 
//     return (
//       <div className={classes.root}>
//         <Grid  
//           container
//         >
//           <Grid item xs={12} sm={6}>
            
//           </Grid>
         
//           <Grid item xs={12} sm={6}>
//             <Requisitos />
//             <CalculadoraPrestamo/>
//           </Grid>

//         </Grid>
//       </div>
        
//     );
// }

// export default Home;

