import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides/index'

let theme = responsiveFontSizes(createMuiTheme({
  palette,
  typography,
  overrides,
}));

export default theme;
