import React,{Fragment} from 'react'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

const useStyles = makeStyles(theme => ({

    actualizar:{
        backgroundColor : '#1976d2',
        borderColor: '#1976d2',
        color:'#ffffff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#2196f3',
            borderColor: '#2196f3',
            boxShadow: 'none',
          },
    },

  }));


const ActButton = (props) => {
const classes = useStyles();
return(
    <Fragment>
        <Button 
        variant="contained" 
        className={classes.actualizar} 
        size="large"
        startIcon={<EditOutlinedIcon />}                            
        display="inline-block" 
        onClick={props.onClickFunction}
        >
            {props.name}
        </Button>
    </Fragment> 
)
}

export default ActButton;

