import React,{ useState} from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import CancelButton from '../../material/cancel-button/CancelButton'

    const getModalStyle = () => {
        const top = 50 ;
        const left = 50 ;
    
        return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
        
    };
  }
  
  const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: 600,      
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
    
      padding: theme.spacing(2, 4, 3),
    },  modalStyle:{
        height:'100%',
        overflow:'scroll',
      },
  
  }));

const ModalError = (props) => {
    const [modalStyle] = useState(getModalStyle);
    const classes = useStyles();  
    const { Close, mensaje } = props;

return(
    <Box>              
      <div 
        style={modalStyle} 
        className={classes.paper}
      >  
        <Box display="flex">
          <Box width="75%" py={1}>
            <Typography 
              variant="subtitle2" 
          
            >  
              Atención
            </Typography>
          </Box>
          <Box 
            width="20%"
            textAlign='right'
          >
            <IconButton 
              aria-label="close" 
              onClick={ Close }
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box py={1}>
          <Divider />  
        </Box>                
        <Typography 
          variant="subtitle1" 
        >
            {mensaje === undefined ? 'Ocurrió un error' : mensaje}
        </Typography>
        <Box 
          pt={3} 
          textAlign='right'
        >
            <CancelButton
                name={'Salir'}
                onClickFunction={Close}
            >
                Salir
            </CancelButton>
        </Box>           
      </div>
  </Box> 
);
}

export default ModalError;