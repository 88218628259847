import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { Card, CardContent, Grid, Typography, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';


const useStyles = makeStyles(theme => ({
    root: {
      height: '100%'
    },
    content: {
      alignItems: 'center',
      display: 'flex'
    },
    title: {
      fontWeight: 700
    },
    avatar: {
      backgroundColor: theme.palette.info.dark,
      height: 56,
      width: 56
    },
    icon: {
      height: 32,
      width: 32
    },
    difference: {
      marginTop: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent:'center',
    },
    differenceIcon: {
      color: theme.palette.success.dark
    },
    differenceValue: {
      color: theme.palette.success.dark,
      marginRight: theme.spacing(1)
    }
  }));

const Ingresos = (props) => {
const {capital, handleOpenIngreso, handleOpenEgreso} = props;

const classes = useStyles();
    return(

        <Card
      
      className={classes.root}
    >
      <CardContent>
        <Grid
          container
          justify="space-between"
        >
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              CAPITAL
            </Typography>
            <Typography variant="h3">${capital.toFixed(2)}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <AccountBalanceIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid> 
        <div className={classes.difference}>
          <IconButton 
            color="primary" 
            aria-label="upload picture" 
            component="span"
            onClick={() => {handleOpenIngreso()}}
          >
            <AddCircleOutlineIcon />
          </IconButton>
          <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            Ingreso
          </Typography>
          <IconButton 
            color="primary" 
            aria-label="upload picture" 
            component="span"
            onClick={() => {handleOpenEgreso()}}
          >
            <RemoveCircleIcon />
          </IconButton>
          <Typography
            className={classes.differenceValue}
            variant="body2"
          >
            Egreso
          </Typography>
        </div>
      </CardContent>
    </Card>
  
    );
}

export default Ingresos;