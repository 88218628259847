import React, { useState, useEffect } from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { CustomButtom } from '../../material/custom-button/CustomButtom'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useSelector, useDispatch } from 'react-redux';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ButtonBase from '@material-ui/core/ButtonBase';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { createLoan, setError } from '../../../store/actions/action'
import ModalMensaje from './ModalMensaje';
import ModalError from './ModalError';
import ModalTerminos from './ModalTerminos';

const useStyles = makeStyles(theme => ({
  modalStyle:{
      height:'100%',
     
    },
}));

const UploadPhoto = (props) => { 
  const user = useSelector(state => state.user);
  const tna = useSelector(state => state.user.tna);
  const error = useSelector(state => state.user.envio_error);
  const envioOk = useSelector(state => state.user.envio_ok);
  
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false);
  const [ errorCheck, setErrorCheck ] = useState(false);
  const [ terminos, setTerminos ] = useState(false);
  const [ label, setLabel ] = useState(0);

  const { nombre , apellido, dni, telefono, direccion, credito, cuotas, cbu, email, fotos} = user;
  const ref = React.createRef()
  const classes = useStyles();    
  
  useEffect(() => {
    if (tna !== null && tna !== undefined ){
      const select = tna.find(array => array.fee === cuotas);
      const feeTna = (((select.percentage)/12)/100);
      const cuotaPura = credito * ((Math.pow(1+feeTna,cuotas)*feeTna)/(Math.pow(1+feeTna,cuotas)-1));
      const interesCuota = ( credito * feeTna );
      const capital = ( cuotaPura - interesCuota );
      const ivaCuota = interesCuota * 0.21; 
      setLabel((ivaCuota+capital+interesCuota).toFixed(0));
    }
  },  [cuotas, credito,tna])
  
  const handleCheck = () => {      
    setCheck(!check);
  };

  const handleSend = () => {    
    if(check){
      const select = tna.find(array => array.fee === cuotas);
      const newLoanRequest = {    
        amount: credito,
        payments: cuotas,
        interestId: select.id,                      //Solucionar desde endpoint
        user: {      
          dni: dni,
          first_name: nombre, 
          last_name: apellido,
          address: direccion,
          phone_number: telefono, 
          province: 'Buenos Aires', 
          city: 'Mar del Plata',                    
          email: email, 
          cbu: cbu,            
          score: 0,
          debtor: 'NO',
        },        
      };      
      dispatch(createLoan(newLoanRequest, fotos));
    }else{
      setErrorCheck(true); //si no acepto terminos y condiciones 
    }
  }
  
  const handleClose = () => {
    dispatch(setError(false));
    setErrorCheck(false)
  };

  const handleOpenTerminos = () => {
    setTerminos(!terminos)
  }
  
  return (
    <Box>
      <Box>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={error}
          onClose={handleClose}
          className={classes.modalStyle}
        >              
          <ModalError
            ref={ref}
            mensaje={'Ocurrió un error en el envió del formulario comuníquese vía correo electrónico info@triacambio.com.ar o al +54922354633409'}
            handleClose={handleClose}
          />
        </Modal>
      </Box>             
      
      <Box>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={envioOk}
          onClose={handleClose} 
          className={classes.modalStyle}
          disableBackdropClick
        >    
          <ModalMensaje ref={ref}/>
        </Modal>
      </Box>          
       
      <Box>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={errorCheck}
          onClose={handleClose}
          className={classes.modalStyle}
        >   
          <ModalError 
            ref={ref}
            mensaje={'Por favor acepte los términos y condiciones'}
            handleClose={handleClose}
          />           
        </Modal>
      </Box>

      <Box>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={terminos}
          onClose={handleOpenTerminos}
          className={classes.modalStyle}
        >   
          <ModalTerminos 
            ref={ref}
            handleClose={handleOpenTerminos}
          />           
        </Modal>
      </Box>
     

      <Box textAlign='left'>
        <Box textAlign='center' pb={3}>
          <Typography variant="h2">
              Detalle del préstamo
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
          <Typography gutterBottom variant="h3">
              Datos del préstamo
          </Typography>
          <ButtonBase onClick={() => props.handleBack(0)}>
            <EditOutlinedIcon />
          </ButtonBase>
        </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom variant="h2">
                Monto
              </Typography>
              <Typography variant="h3">
                ${credito}
              </Typography>
            </Grid >
            <Grid item xs={12} md={6}>
              <Typography gutterBottom variant="h2">
                Cuotas
              </Typography>
              <Typography variant="h3">
                {cuotas} cuotas fijas de ${label}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box display="flex" flexDirection="row" alignItems="center">
                <Typography variant="h2">
                  CBU
                </Typography>
                <ButtonBase onClick={() => props.handleBack(2)}>
                  <EditOutlinedIcon />
                </ButtonBase>
              </Box>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Typography noWrap variant="h3">
                {cbu}
              </Typography>
            </Grid>
          </Grid>

          <Box mt={3}>
            <Divider/>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center" mb={2} mt={3}>
            <Typography gutterBottom variant="h3">
                Detalles Personales
            </Typography>
            <ButtonBase onClick={() => props.handleBack(1)}>
              <EditOutlinedIcon />
            </ButtonBase>
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom variant="h2">
                Nombre  y apellido
              </Typography>
              <Typography variant="h3">
                {nombre}, {apellido}.
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom variant="h2">
                DNI
              </Typography>
              <Typography variant="h3">
                {dni}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography gutterBottom variant="h2">
                Teléfono
              </Typography>
              <Typography variant="h3">
                {telefono}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom variant="h2">
                Dirección
              </Typography>
              <Typography variant="h3">
                {direccion}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box 
                display="flex" 
                flexDirection="row"  
                justifyContent="center" 
                alignItems="center"
              >
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox                                       
                        value="checkedB"
                        onChange={handleCheck}
                      />
                    }
                  />
                </Box>
                <Box>
                  <Typography variant="h4">
                    <ButtonBase onClick={handleOpenTerminos}>He leído y acepto los términos y condiciones</ButtonBase> 
                  </Typography>
                </Box>
                
                 
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box textAlign='center'  >
                <CustomButtom  
                  variant="contained" 
                  color="primary"  
                  onClick={handleSend}
                  >
                    Confirmar 
                  </CustomButtom>             
              </Box> 
            </Grid>    

          </Grid>
      </Box>
    </Box>
  );
}

  export default UploadPhoto;