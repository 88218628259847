export const colors = {
    greentria: '#018c2f',
    white: '#ffffff',
    gray: '#999999',
    lightgraybg: '#f2f3f5',
    graybgheader: '#333',
    black: '#000000',
    orange: '#fd6802',
    graybgtoggler: '#393939',
    greenbgcard: '#06b942',
    greenbgcard2: '#2fbc5e',
    greenshadowthumb: '#01a537',
    blackshadowtrack: '#0d0d0d'
}
