import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { CustomButtom } from '../../material/custom-button/CustomButtom'
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';

const getModalStyle = () => {
    const top = 50 ;
    const left = 50 ;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      
    };
  }
  
  const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: 600,
      maxHeight: '50%',      
      maxWidth: '95%',      
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
    
      padding: theme.spacing(2, 4, 3),
    },  modalStyle:{
        height:'100%',
        overflow:'scroll',
      },
    subtitle2:{
      fontWeight: 300,
      fontSize: '2rem',
    },
    subtitle1:{
      fontWeight: 300,
      fontSize: '1.3rem',
    },
  }));

const ModalError = React.forwardRef ((props, ref) => {
    const [modalStyle] = useState(getModalStyle);
    const classes = useStyles();  

    const {handleClose, mensaje } = props;
    
    return(
        <div 
              style={modalStyle} 
              className={classes.paper}
            >  
              <Box display="flex">
                <Box 
                  width="75%" 
                  py={1}
                >
                  <Typography 
                    variant="subtitle2" 
                    className={classes.subtitle2}  
                  >  
                    Error
                  </Typography>
                </Box>
                  <Box width="20%" textAlign='right'>
                    <IconButton aria-label="close" onClick={handleClose}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
              </Box>
              <Box py={1}>
                <Divider />  
              </Box>   
              <Box>
                <Typography 
                  variant="subtitle1" 
                  className={classes.subtitle1} 
                >
                  { mensaje } 
                </Typography>
                <Box 
                  textAlign='right'
                >
                  <CustomButtom
                    variant="contained" 
                    color="primary"  
                    onClick={handleClose}
                  >
                    Aceptar
                  </CustomButtom>
                </Box>           
              </Box>             
        </div>
    );  
})


export default ModalError;
