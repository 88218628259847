
export default {
    underlineNone:{
        underline:"none"
      },
    underlineHover:{
        //underline:"none"
    },
    underlineAlways:{
        //underline:"none"
    },

};
