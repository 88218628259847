import React from "react";
import "./styles.scss";

const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="row">
        <div className="col-md-8 col-sm-8 col-xs-12">
          <div className="title-footer">
            <span className="title-tria">TRIA</span>
            <span>PRESTAMOS</span>
          </div>
          <p className="description-footer">
            TRIAPRESTAMOS nace en la ciudad de Mar del Plata con el objetivo de afianzarse en el mercado
            financiero, otorgando  una alternativa de créditos personales con requisitos mínimos y procedimientos sencillos, 
            donde el compromiso de la empresa es posibilitar que los anhelos se conviertan en realidad.
          </p>
        </div>
        <div className="col-md-4 col-sm-4 col-xs-12">
          <div className="clearfix">
            <div className="horarios-footer">
              <h3 className="title-horario-footer">Horarios de atención</h3>
            </div>
            <p>Lunes a viernes 9:30 a 16 hs.</p>
            <div className="container-instagram">
              <a target="_blanck" href="https://www.instagram.com/triaprestamos/">
                <i className="fab fa-instagram"></i>
                Instagram
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
