import React,{ useState, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import { useDispatch, useSelector } from 'react-redux';
import ModalIngreso  from './ModalIngreso';
import ModalEgreso  from './ModalEgreso';
import ModalResultado  from './ModalResultado';
import ModalError from '../ModalError';
import ModalCierre from './ModalCierre';
import ModalMensaje from '../ModalMensaje';
import { getCapital, getCaja, setErrorClear, setErrorMessage, getCirculacion, getIValoresMes} from '../../../../store/actions/adminActions';
import Caja from './components/Caja'
import Capital from './components/Capital'
import Grid from '@material-ui/core/Grid';
import { setCierreMes } from '../../../../store/actions/adminActions';
import Circulacion from './components/Circulacion';
import Iva from './components/Iva';
import InteresComp from './components/InteresComp';
import Gastos from './components/Gastos';


const Estadisticas = () => {
    const [openIngreso, setOpenIngreso] = useState(false);
    const [openEgreso, setOpenEgreso] = useState(false);
    const [openCierre, setOpenCierre] = useState(false);
    const [openResultado, setOpenResultado] = useState(false);
    const [resultados, setResultados] = useState(null);

    const dispatch = useDispatch();
    const ref = React.createRef()

    useEffect(() => {      
        dispatch(getCapital());
        dispatch(getCaja());
        dispatch(getCirculacion());
        dispatch(getIValoresMes());
      }, [dispatch])

    const capital = useSelector(state => state.admin.capital);  
    const caja = useSelector(state => state.admin.caja); 
    const errorModal = useSelector(state => state.admin.error);
    const mensajeModal = useSelector(state => state.admin.mensaje);
    const circulacion = useSelector(state => state.admin.circulacion);
    const iva = useSelector(state => state.admin.iva);
    const intereses = useSelector(state => state.admin.intereses);
    const gastos = useSelector(state => state.admin.gastos);


    const handleOpenResultado = () => {
        setOpenResultado(true);
    };

    const handleCloseResultado = () => {
        setOpenResultado(false);
    };

    const handleOpenCierre = () => {
        setOpenCierre(true);
    };

    const handleCloseCierre = () => {
        setOpenCierre(false);
    };

    const handleCierreMes = async () =>{
        const res = await dispatch(setCierreMes());
        dispatch(getCapital());
        dispatch(getCaja());
        dispatch(getCirculacion());
        dispatch(getIValoresMes());
        handleCloseCierre();
        setResultados(res);
        handleOpenResultado()
      }

    const handleOpenIngreso = (target) => {
        dispatch(getCapital());
        dispatch(getCaja());
        setOpenIngreso(true);
    };

    const handleCloseIngreso = () => {
        setOpenIngreso(false);
    };

    const handleOpenEgreso = (target) => {
        dispatch(getCapital());
        dispatch(getCaja());
        setOpenEgreso(true);
    };

    const handleCloseEgreso = () => {
        setOpenEgreso(false);
    };

return (
    <Box>
        <Box>
            <Modal
                open={openCierre}
                onClose={handleCloseCierre}   
            >   
                <ModalCierre
                    ref={ref}
                    handleCierreMes={handleCierreMes}
                    Close={handleCloseCierre}                
                />
            </Modal>

            <Modal
                open={openIngreso}
                onClose={handleCloseIngreso}   
            >   
                <ModalIngreso
                    ref={ref}
                    Caja={caja}
                    Capital={capital}
                    Close={handleCloseIngreso}                
                />
            </Modal>

            <Modal
                open={openEgreso}
                onClose={handleCloseEgreso}            
            >   
                <ModalEgreso 
                    ref={ref}
                    Caja={caja}
                    Capital={capital}
                    Close={handleCloseEgreso}                
                />
            </Modal> 

            <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={mensajeModal}
                    onClose={() => dispatch(setErrorMessage(false)) }
                > 
                    <ModalMensaje
                        Close={() =>  dispatch(setErrorMessage(false)) }
                        mensaje='Monto insuficiente'
                    />
            </Modal> 

            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={errorModal}
                onClose={() => dispatch(setErrorClear()) }
                > 
                    <ModalError
                        Close={() => dispatch(setErrorClear()) }
                    />
            </Modal> 

            <Modal
                open={openResultado}
                onClose={handleCloseResultado}   
            >   
                <ModalResultado
                    ResultadosCierre={resultados}
                    Close={handleCloseResultado}                
                />
            </Modal>
            
        </Box>
        <Grid
            container
            spacing={4}
        >
            <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
                >
                    <Caja
                      caja={caja}  
                      handleOpenCierre={handleOpenCierre}
                    />
            </Grid>

            <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
                >
                    <Capital 
                        capital={capital}
                        handleOpenIngreso={handleOpenIngreso}
                        handleOpenEgreso={handleOpenEgreso}
                    />
            </Grid>
 
            <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
                >
                    <Circulacion 
                        circulacion={circulacion}
                    />
            </Grid>

            <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
                >
                    <Iva 
                        iva={iva}
                    />
            </Grid>

            <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
                >
                    <InteresComp 
                        intereses={intereses}
                    />
            </Grid>

            <Grid
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
                >
                    <Gastos 
                        gastos={gastos}
                    />
            </Grid> 
            
        </Grid>
    </Box>    
); 
}

export default Estadisticas;
