import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import {Redirect} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { loginAdmin } from "../../store/actions/adminActions"; 
import Fondo from '../../img/billete.jpg'
import Box from '@material-ui/core/Box';
//import palette from '../../theme/palette'

const BackgroundHead = {
  height: '100%',
  width:'100%',
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: { 
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Login = () => {
    const [form , setForm] = useState([ { user: '', password: '' } ]);
    const [err , setError] = useState(false);
    const dispatch = useDispatch();
    const isLogged = useSelector(state => state.admin.isLogged);
    const isLogin = useSelector(state => state.admin.isLogin);
    const classes = useStyles();

    const handleChange = (e) => {
        let {name , value} = e.target;
        setForm({...form, [name]: value});
    }

    const handleSubmit = async () => {
      const payload ={
        userName: form.user,
        password: form.password,
      }
      const error = await dispatch(loginAdmin(payload));
      if(error === true){
        setError(true)
      }
    }  

    if (isLogged) {
        return <Redirect to='/admin-panel/prestamos'/>;
      }
    
return(
  <Grid container component="main" className={classes.root}>
  <CssBaseline />
    <Grid item xs={false} sm={4} md={7} className={classes.image} >
      <div style={BackgroundHead}>
        <img 
            src={Fondo} 
            height='100%' 
            width='100%' 
            alt='login'
        /> 
      </div>
    </Grid>
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <Box 
        display='flex' 
        flexDirection='column'
        justifyContent="center"
        alignItems='center'
        height='100%'
        mx={10}
      >
          <Typography  variant="h2">
            Panel de administrador
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              label="Ingrese su usuario"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="user"
              name="user"
              autoFocus
              onChange={e => handleChange(e)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Ingrese contraseña"
              type="password"
              id="password"
              error={err === false ? false : true}
              helperText={err === false ? '' : 'Contraseña o usuario incorrecto'}
              onChange={e => handleChange(e)}
            />       
            <Button          
              onClick={handleSubmit}
              fullWidth
              variant="contained"
              color="primary"            
              className={classes.submit}
            >
              {isLogin === false ? 'ACCEDER' : <CircularProgress color='#FFFFFF' size={25}/>}
            </Button>
          </form>
      </Box>
    </Grid>
</Grid>
);    
}

export default Login;


