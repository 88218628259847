import React from "react";
import "./App.css";
import { Route, Switch, Redirect, Router } from "react-router-dom";
import history from "./history";
import { useSelector, useDispatch } from 'react-redux';
import Faqs from './components/faqs/Faqs'
import Login from './components/login/Login'
import AdminPanel from './components/adminpanel/AdminPanel';
import Home from "./components/home/Home";
import StepFrom from './components/step-form';
import DefaultLayoutRoute from "./components/layout";
import Prestamos from './components/adminpanel/components/prestamos/Prestamos';
import Estadisticas from './components/adminpanel/components/estadisticas/Estadisticas';
import Formulas from './components/adminpanel/components/formulas/Formulas';
import { getInfoUser } from './store/actions/action';
import { autoLogin } from './store/actions/adminActions';
import NotFound from './components/404/NotFound';

const App = (props) => { 
  const isLogged = useSelector(state => state.admin.isLogged);
  const dispatch = useDispatch();
  dispatch(getInfoUser());
  dispatch(autoLogin());

    return (
      <Router history={history}>        
        <Switch>
          
          <Route exact path="/">
            <Redirect to={`/calculadora-prestamo`} />
          </Route>

          <DefaultLayoutRoute
            path={`/calculadora-prestamo`}
            isLogged={isLogged}
            component={Home}
          />
          
          <DefaultLayoutRoute
            path={`/generador-de-solicitud`}
            exact
            isLogged={isLogged}
            component={StepFrom}
          />

          <DefaultLayoutRoute
            path={`/preguntas-frecuentes`}
            exact
            isLogged={isLogged}
            component={Faqs}
          />

          <DefaultLayoutRoute
            path={`/login`}    
            exact        
            isLogged={isLogged}
            component={Login}
          />  

          <DefaultLayoutRoute                      
            component={AdminPanel}
            exact
            layout={Formulas}
            path={`/admin-panel/formulas`}
            isLogged={isLogged}
          />  

          <DefaultLayoutRoute                      
            component={AdminPanel}
            exact
            layout={Prestamos}
            path={`/admin-panel/prestamos`}
            isLogged={isLogged}
          />  

          <DefaultLayoutRoute                      
            component={AdminPanel}
            exact
            layout={Estadisticas}
            path={`/admin-panel/estadisticas`}
            isLogged={isLogged}
          />  

          <Route>
            <NotFound />
          </Route>

        </Switch>
      </Router>
    );
  }


export default App;

