import * as actionTypes from '../actions/action';

const initialState = {
  credito: 25000,
  cuotas: 9,
  creditoMax: 100000,
  creditoMin: 10000,
  cuotaMin:6,
  cuotaMax:36,
  cbu:'',
  nombre:'',
  apellido:'',
  dni: '',
  telefono: '',
  direccion: '',
  ciudad:'Mar del Plata',
  email:'',
  fotos: [{'primera': null}, {'segunda':null}, {'tercera':null}],
  tna: null,
  envio_ok: false,
  envio_error:false,
}

const user = (state = initialState, action ) => {
    switch (action.type) {

      case actionTypes.GET_CONFIG_USER:  
        return {
          ...state,
          creditoMax: action.config[1].maxValue,
          creditoMin: action.config[1].minValue,
          cuotaMin: action.config[0].minValue,
          cuotaMax: action.config[0].maxValue,
      };

      case actionTypes.SET_CHANGE:          
        return {
          ...state,
          [action.fieldName] : action.fieldValue,
      };

      case actionTypes.DELETE_FILE: {        
        let newArray = [...state.fotos];          
        newArray[action.index] = {[action.foto ]: null};
        return{
          ...state,
          fotos: newArray
        }
      }

      case actionTypes.ADD_FILE:
        let index = null;
          if(action.fileName === 'primera'){
            index = 0
          }else if(action.fileName === 'segunda'){
            index = 1
          }else{
            index = 2
          }
        let newArray = [...state.fotos];
        newArray[index] = {[action.fileName ]: action.file};
        return {
          ...state,
          fotos: newArray
        }
        
        case actionTypes.GET_TNA_USER:
          return{
            ...state,
            tna : action.tna
          }

          case actionTypes.SET_ERROR:
            return{
              ...state,
              envio_error : action.state,
            }

          case actionTypes.SET_ENVIO_OK:
            return{
              ...state,
              envio_ok : action.state,
            }

          case actionTypes.SET_DEFAULT:
            return{
              credito: 25000,
              cuotas: 9,
              cbu:'',
              nombre:'',
              apellido:'',
              dni: '',
              telefono: '',
              direccion: '',
              ciudad:'Mar del Plata',
              email:'',
              fotos: [{'primera': null}, {'segunda':null}, {'tercera':null}],
              tna: null,
              envio_ok: false,
              envio_error:false,
            }  
            
        default:
          return state;
    }
};
    
export default user;
