import React,{ useState} from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

    const getModalStyle = () => {
        const top = 50 ;
        const left = 50 ;
    
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
    };
  }
  
  const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: 600,      
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },  modalStyle:{
        height:'100%',
        overflow:'scroll',
      },
  
  }));

const ModalResultado = React.forwardRef ((props, ref) => {
    const [modalStyle] = useState(getModalStyle);
    const classes = useStyles(); 
    const { Close, ResultadosCierre} = props;
    
    console.log('aca', ResultadosCierre)
return(
        
      <div 
        style={modalStyle} 
        className={classes.paper}
      >  
        <Box display="flex">
          <Box width="75%" py={1}>
            <Typography 
              variant="subtitle2" 
            >  
              RESULTADOS DEL CIERRE DE MES
            </Typography>
          </Box>
          <Box 
            width="20%"
            textAlign='right'
          >
            <IconButton 
              aria-label="close" 
              onClick={ Close }
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box py={1}>
          <Divider />  
        </Box> 
        <Box>
            <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                <Typography gutterBottom variant="h2">
                    IVA
                </Typography>
                <Typography variant="h4">
                    ${  ResultadosCierre ? (ResultadosCierre.iva).toFixed(2) : 0 }
                </Typography>
                </Grid >
                <Grid item xs={12} md={6}>
                <Typography gutterBottom variant="h2">
                    Ganancias
                </Typography>
                <Typography variant="h4">
                    ${ ResultadosCierre ? (ResultadosCierre.totalEarnings).toFixed(2) : 0 }
                </Typography>
                </Grid>
            </Grid>
        </Box>
      </div>

);
})


export default ModalResultado;