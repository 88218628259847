import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {setChange} from '../../../store/actions/action';
import { useSelector, useDispatch} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import {CustomButtom} from '../../material/custom-button/CustomButtom';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import useForm from '../../hooks/useForm'

 const InfoPersonal = (props)  => {
  const [open, setOpen] = useState(false);
   const { handleNext } = props;
  const dispatch = useDispatch();

  const st = useSelector(state => state.user);
  const { nombre , apellido, dni, telefono, direccion, ciudad, email} = st;
  
  const {inputs, handleInputChange, inputsErrors} = useForm({nombre, apellido, dni, telefono, direccion, ciudad, email}, {nombre: '', apellido: '', dni: '', telefono: '',direccion: '', ciudad:'',email:''});
 
  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if(inputsErrors.nombre === '' && inputsErrors.apellido === '' && inputsErrors.direccion === '' && inputsErrors.telefono === '' && inputsErrors.dni === ''){
      dispatch(setChange('nombre',inputs.nombre)) //Fatan implementar un destructuring en el reducer para eliminar tantos dispatch
      dispatch(setChange('apellido',inputs.apellido))
      dispatch(setChange('direccion',inputs.direccion))
      dispatch(setChange('telefono',inputs.telefono))          
      dispatch(setChange('email',inputs.email))
      dispatch(setChange('dni',inputs.dni)) 
      handleNext();    
    }else{
      setOpen(true);
    }
  }

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
         
  return (
    <div>
      <Snackbar open={open} autoHideDuration={3000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error">
          Complete los campos requeridos
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Grid container>
          <Grid item xs={12} md={6}>
            <TextField
              label="Nombre"
              id="nombre"
              name="nombre" 
              margin="normal"
              onChange={handleInputChange} 
              value={inputs.nombre}
              helperText={inputsErrors.nombre} 
              onBlur={handleInputChange} 
              error={inputsErrors.nombre !== '' ? true : false}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label="Apellido"
              id="apellido"
              name="apellido"
              margin="normal"         
              onChange={handleInputChange} 
              value={inputs.apellido}
              helperText={inputsErrors.apellido} 
              onBlur={handleInputChange} 
              error={inputsErrors.apellido !== '' ? true : false}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Dni"
              id="dni"
              name="dni"
              margin="normal"
              onChange={handleInputChange} 
              value={inputs.dni}
              helperText={inputsErrors.dni} 
              onBlur={handleInputChange} 
              error={inputsErrors.dni !== '' ? true : false}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              label="Direccion"
              id="direccion"
              name="direccion"
              margin="normal" 
              onChange={handleInputChange} 
              value={inputs.direccion}
              helperText={inputsErrors.direccion} 
              onBlur={handleInputChange} 
              error={inputsErrors.direccion !== '' ? true : false}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField              
              label="Email"
              id="email"
              name='email'  
              margin="normal"        
              onChange={handleInputChange} 
              value={inputs.email}
              helperText={inputsErrors.email} 
              onBlur={handleInputChange} 
              error={inputsErrors.email !== '' ? true : false}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
          <TextField
            label="Télefono"
            id="telefono"    
            name="telefono"
            margin="normal"        
            onChange={handleInputChange} 
            value={inputs.telefono}
            helperText={inputsErrors.telefono} 
            onBlur={handleInputChange} 
            error={inputsErrors.telefono !== '' ? true : false}
            required
          />
        </Grid> 

           <Grid item xs={12} md={6}>
            <TextField
              label="Ciudad"
              id="ciudad"      
              name="ciudad"      
              margin="normal"                                          
              value={inputs.ciudad}             
              onBlur={handleInputChange}              
              required
            />            
          </Grid> 

        </Grid>

        <Box textAlign='center' pt={5}>
          <CustomButtom
            variant="contained"
            color="primary"
            type="submit"
            >
              Siguiente
          </CustomButtom>            
        </Box> 
      </form>
    </div>
  );
}

export default InfoPersonal;

