import React from "react";
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    fontSize: '18px',
    fontWeight: 600,
    width:"30%",
    height:'30%',
    margin: '1%',
    flexDirection:'column',
  },
  icon:{
    width:"80%",
    height:'80%',
    filter: 'invert(1)',
    margin: '3%',
  },
  font1:{
    color: theme.palette.black,
    fontWeight:600,
    [theme.breakpoints.up('xs')]: {
      fontSize: 15,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 30,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 30,
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 25,
    },
}
}));

const IconCard = (props) => {
  const  {icon, message, color} = props
  const classes = useStyles();
  return(
      <Box   
        className={classes.root}
        style={{backgroundColor: color}}
      > 
        <Box m={1} width='80%' height='55%'>
          <img 
            src={icon} alt="arq" 
            className={classes.icon} 
          />
        </Box>
        <Box pt={1} height='45%' width='100%'>
          <Typography  className={classes.font1}>{message}</Typography>
        </Box>
      </Box>
  );
}

export default IconCard;
