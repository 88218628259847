import React, { useState, useEffect} from 'react';
import Box from '@material-ui/core/Box';
import MaterialTable from 'material-table';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import ModalAprobados from './ModalAprobado';
import ModalPagos from './ModalPagos';
import { useDispatch, useSelector } from 'react-redux';
import {getTaxes, getLoanPayments, getUserImg, cleanPayment, getPrestamoByStatus} from '../../../../../../store/actions/adminActions';
import palete from '../../../../../../theme/palette';

const useStyles = makeStyles(theme => ({
modalStyle:{
    height:'100%',
    },
}));

const Aprobados = (props) =>{
    //DECLARATIONS
    const classes = useStyles();
    const tableRef = React.useRef();
    const { tableIcons } = props;
    //LOCAL STATE
    const [openAprobado, setOpenAprobado] = useState(false);
    const [openPagos, setOpenPagos] = useState(false);
    const [ modalRow, setmodalRow] = useState({
        id:null,
        userId:null,
        nombre:null, 
        apellido:null,
        dni:null,
        telefono:null,  
        direccion:null,
        cbu:null,                                               
        email:null,                                                                                                                                                     
        prestamo: null,
        cuotas:null,
        tna:null,
        score: null,
        deudor: null,
    }); 
    //HOOKS
    const dispatch = useDispatch();
    const payment = useSelector(state => state.admin.payment)
  
    useEffect(() => {      
        dispatch(getTaxes());
      }, [dispatch])

    function refreshTable() {
        tableRef.current && tableRef.current.onQueryChange()
    }

    const handleOpenAprobado = (event, rowData) => {
        dispatch(getUserImg(rowData.id));
        setmodalRow({
            id: rowData.id,
            userId: rowData.userId,
            interestId: rowData.interestId,
            nombre: rowData.first_name, 
            apellido: rowData.last_name,
            dni: rowData.dni,
            telefono: rowData.phone_number,  
            direccion: rowData.address,
            cbu:rowData.cbu,                                               
            email:rowData.email,                                                                                                                                                     
            prestamo: rowData.amount,
            cuotas: rowData.payments,
            tna: rowData.percentage,
            score: rowData.score,
            deudor: rowData.debtor,
        })  
        setOpenAprobado(true);
    };
  
    const handleCloseAprobado = () => {
        //limpiar reducer! 
        setOpenAprobado(false);
    };

    const handleOpenPagos = async (event, rowData) => {
        const date = new Date();
        await dispatch(getLoanPayments(rowData.id, date))
        .then(setOpenPagos(true))
    };
  
    const handleClosePagos = () => {
        setOpenPagos(false);
        dispatch(cleanPayment());
    };

return(
    <Box >     
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openAprobado}
            onClose={handleCloseAprobado}
            className={classes.modalStyle}
        >
            <ModalAprobados
                formValues={modalRow}
                Close={() =>handleCloseAprobado()} 
                refreshTable={() =>refreshTable()}  
                ref={tableRef} 
            />
        </Modal>
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openPagos}
            onClose={handleClosePagos}
            className={classes.modalStyle}
        >
            <ModalPagos
                Payment={payment}
                Close={() =>handleClosePagos()}
                refreshTable={() =>refreshTable()} 
                ref={tableRef}  
            />
        </Modal>      

        <MaterialTable
            icons={tableIcons}
            title=''
            tableRef={tableRef}
            localization={{
                pagination: {
                    labelRowsSelect: 'Filas',
                    labelDisplayedRows: ' {from}-{to} de {count}',
                    firstTooltip: 'Primera Página',
                    previousTooltip: 'Página Previa ',
                    nextTooltip: 'Pagina Siguiente',
                    lastTooltip: 'Última Página'
                },
                toolbar: {
                    nRowsSelected: '{0}Filas(s) seleccionadas'
                },
                header: {
                    actions: 'Acciones'
                },
                body: {
                    emptyDataSourceMessage: 'Sin registro para mostrar',
                }
            }}
            options={{
                search: false,
                actionsColumnIndex: -1,      
                exportButton: true, 
                filtering: true, 
                cellStyle: {
                    color: palete.secondary.main,
                },
                headerStyle: {
                    color: palete.white,
                    backgroundColor: palete.secondary.main,
                },   
            }}
            columns={[
                { title: 'Fecha', field: 'date', filtering: false },   
                { title: 'Dni', field: 'dni'},
                { title: 'Nombre', field: 'first_name'},
                { title: 'Apellido', field: 'last_name'},
                { title: 'Telefono.', field: 'phone_number', filtering: false},
                { title: 'Monto', field: 'amount', type: 'numeric', filtering: false },
                { title: 'Cuotas', field: 'payments', type: 'numeric', filtering: false }, 
                { title: 'TNA', field: 'percentage', type: 'numeric' , filtering: false},                                
            ]}
            data={query => 
                new Promise((resolve, reject)  => {
                     dispatch(getPrestamoByStatus('APROBADO', query))
                    .then(res => {
                        resolve({
                            data: res.data.loansList,
                            page: query.page,
                            totalCount: res.data.loanCount,
                            search: query.filters,
                        });     
                    })
                    .catch(
                        err => {
                            return reject
                    })
                })
            }        
            actions={[
                {
                    icon: tableIcons.Search,
                    tooltip: 'Informacion detallada',
                    onClick: (event, rowData) => handleOpenAprobado(event, rowData)
                },
                {
                    icon: tableIcons.Money,
                    tooltip: 'Agregar pago',
                    onClick: (event, rowData) => handleOpenPagos(event, rowData)
                },
                {
                    icon: tableIcons.Refresh,
                    tooltip: 'Refresh Data',
                    isFreeAction: true,
                    onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                }
            ]}
        />
    </Box>
);
}

export default React.memo(Aprobados);