import React, { useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorMessage } from '../../../../../../store/actions/adminActions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Modal from '@material-ui/core/Modal';
import ModalImg from '../SimpleModal/ModalImg'
import ActButton from '../../../../../material/Act-button/ActButton'
import CancelButton from '../../../../../material/cancel-button/CancelButton'
import Approvalbutton from '../../../../../material/Approval-button/ApprovalButton'
import CloseButton from '../../../../../material/Close-Button/CloseButton';
import ModalMensaje from '../../../ModalMensaje';

  const getModalStyle = () => {
    const top = 50 ;
    const left = 50 ;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      
    };
  }

  const useStyles = makeStyles(theme => ({
     paper: {
      position: 'absolute',
      width: 900,    
      height: '90%',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    modalStyle:{
        height:'100%',       
    },
    dateSelect:{
        width: '70%',
        marginTop:12,
    
    }
  }));

  const ModalPendiente = React.forwardRef ((props, ref) => {
    //DECLARATIONS
    const classes = useStyles();
    const { Close, formValues, Aprobar, Denegar, Actualizar} = props;
    //LOCAL STATE
    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);
    const [idTna, setIdTna] = useState(props.formValues.interestId);
    const [imgModal, setImgModal] = useState('');
    const [moldaValues, setFormValues] = useState({
        id: formValues.id,
        userId: formValues.userId,
        tnaId: idTna,
        nombre: formValues.nombre,
        apellido: formValues.apellido,
        dni: formValues.dni,
        telefono: formValues.telefono,  
        direccion: formValues.direccion,
        cbu: formValues.cbu,                                               
        email: formValues.email,                                                                                                                                                     
        prestamo: formValues.prestamo,
        cuotas: formValues.cuotas,
        tna: formValues.tna,
        score: formValues.score,
        deudor: formValues.deudor,
        img:[],
    }); 
    //HOOKS
    const dispatch = useDispatch();
    const arrayImg = useSelector(state => state.admin.arrayImg)
    const caja = useSelector(state => state.admin.caja)
    const mensajeModal = useSelector(state => state.admin.mensaje);
    
    useEffect(() => {        
        //CARGA LOS TNAS DISPONIBLES EN LA BD EN EL SELECT
        const select = props.tnaList.filter(tna => moldaValues.tna === tna.percentage );
        setFormValues({
            ...moldaValues,
            'tnaId': select[0].id,
        });        
        setIdTna(select[0]);

        //CARGA LAS IMAGENES SI TUVIERE UNA VARIABLE
        let arry = [];
        arrayImg.forEach(element => {
            if (element.url.includes('front')){
                let str = element.url.split("\\");
                arry[0] = str[str.length-1];
            }else if(element.url.includes('back')){
                let str = element.url.split("\\");
                arry[1] = str[str.length-1];;
            }else if(element.url.includes('selfie')){
                let str = element.url.split("\\");
                arry[2] = str[str.length-1];;
            }
        });
        
        //AGREGA EL NOMBRE DE IMAGENES AL ESTADO DEL COMPONENTE 
        setFormValues({
            ...moldaValues,
            'img': arry,
        });

      }, [props.tnaList, moldaValues.tna, arrayImg])
     
    const handleChange = (event) => {         
        setFormValues({
            ...moldaValues,
            [event.target.name]: event.target.value
        });
    };

    const handleActualizar = () => {
       const payload = {
        id:moldaValues.id,
        amount: moldaValues.prestamo,
        payments: moldaValues.cuotas,
        interestId: moldaValues.tnaId,                      
        user: {  
          id:moldaValues.userId,    
          dni: moldaValues.dni,
          first_name: moldaValues.nombre, 
          last_name: moldaValues.apellido,
          address: moldaValues.direccion,
          phone_number: moldaValues.telefono, 
          province: 'Buenos Aires', 
          city: 'Mar del Plata',                    
          email: moldaValues.email, 
          cbu: moldaValues.cbu,            
          score: moldaValues.score,
          debtor: moldaValues.deudor,   
          } 
        }
        Actualizar(payload);   
        Close();
    }

    const handleAprobar = (rowData) => {
        if(caja > moldaValues.prestamo){
            const payload = {
                id:moldaValues.id,
                amount: moldaValues.prestamo,
                payments: moldaValues.cuotas,
                interestId: moldaValues.tnaId, 
                status:"APROBADO",                        
                user: {  
                  id:moldaValues.userId,    
                  dni: moldaValues.dni,
                  first_name: moldaValues.nombre, 
                  last_name: moldaValues.apellido,
                  address: moldaValues.direccion,
                  phone_number: moldaValues.telefono, 
                  province: 'Buenos Aires', 
                  city: 'Mar del Plata',                    
                  email: moldaValues.email, 
                  cbu: moldaValues.cbu,            
                  score: moldaValues.score,
                  debtor: moldaValues.deudor,   
                  } 
                }
            Aprobar(payload);    
            Close();
        }else{
            dispatch(setErrorMessage(true));
        }
    }

    const handleDenegar = () => {
        const payload = {
           id:moldaValues.id,
           amount: moldaValues.prestamo,
           payments: moldaValues.cuotas,
           interestId: moldaValues.tnaId, 
           status:"DENEGADO",                        
           user: {  
             id:moldaValues.userId,    
             dni: moldaValues.dni,
             first_name: moldaValues.nombre, 
             last_name: moldaValues.apellido,
             address: moldaValues.direccion,
             phone_number: moldaValues.telefono, 
             province: 'Buenos Aires', 
             city: 'Mar del Plata',                    
             email: moldaValues.email, 
             cbu: moldaValues.cbu,            
             score: moldaValues.score,
             debtor: moldaValues.deudor,   
             } 
           }
        Denegar(payload);   
        Close(); 
    }

    const handleClose = () => {
        setOpen(false);
      };
  
    const handleOpen = (nombre) => {
        //SETEA EN EL ESTADO 
        if(nombre === 'frontal'){
            setImgModal(moldaValues.img[0]);
        } else if(nombre === 'posterior'){
            setImgModal(moldaValues.img[1]);
        } else{
            setImgModal(moldaValues.img[2]);
        }  
        setOpen(true);
    };  

    return(
    <Box >  
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={handleClose}
            className={classes.modalStyle}
        >   
            <ModalImg 
                Close={() =>handleClose()}  
                imgModal={imgModal} 
                ref={ref}                 
            />
        </Modal> 

        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={mensajeModal}
            onClose={() => dispatch(setErrorMessage(false)) }
            className={classes.modalStyle}
        > 
            <ModalMensaje
                Close={() =>  dispatch(setErrorMessage(false)) }
                mensaje='No hay disponibilidad de fondos para entregar el prestamo'
            />
        </Modal> 

        <div style={modalStyle} className={classes.paper}>
            <div style={{overflowY: 'scroll', height:'100%'}}>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" >
                    <CloseButton
                        name={''}
                        onClickFunction={Close}
                    />
                </Box>    
                <Box textAlign='center' >       
                    <Typography variant="h3">DATOS DEL PRESTAMO</Typography> 
                    <FormControl className={classes.formControl}>     
                        <Grid container>
                            <Grid item xs={12} md={6}>         
                                <TextField
                                    label="Nombre"
                                    id="nombre"
                                    name="nombre" 
                                    margin="normal"                       
                                    value={moldaValues.nombre}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>   
                                <TextField
                                    label="Apellido"
                                    id="apellido"
                                    name="apellido" 
                                    margin="normal"                        
                                    value={moldaValues.apellido}
                                    onChange={handleChange}
                                />
                            </Grid>  
                            <Grid item xs={12} md={6}> 
                                <TextField
                                    label="DNI"
                                    id="dni"
                                    name="dni" 
                                    margin="normal"                        
                                    value={moldaValues.dni}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}> 
                                <TextField
                                    label="Teléfono"
                                    id="telefono"
                                    name="telefono" 
                                    margin="normal"
                                    value={moldaValues.telefono}
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}> 
                                <TextField
                                    label="Dirección"
                                    id="direccion"
                                    name="direccion" 
                                    margin="normal"        
                                    value={moldaValues.direccion}
                                    onChange={handleChange}
                                />
                            </Grid>  
                            <Grid item xs={12} md={6}>  
                                <TextField
                                    label="CBU"
                                    id="cbu"
                                    name="cbu" 
                                    margin="normal"                       
                                    value={moldaValues.cbu}
                                    onChange={handleChange}
                                />
                            </Grid>  
                            <Grid item xs={12} md={6}>  
                                <TextField
                                    label="Email"
                                    id="email"
                                    name="email" 
                                    margin="normal"                       
                                    value={moldaValues.email}   
                                    onChange={handleChange}                    
                                />
                            </Grid> 
                            <Grid item xs={12} md={6}>  
                                <TextField
                                    label="Préstamo"
                                    id="prestamo"
                                    name="prestamo" 
                                    margin="normal"
                                    value={moldaValues.prestamo}
                                    onChange={handleChange}     
                                />
                            </Grid> 
                            <Grid item xs={12} md={6}>  
                                <TextField
                                    label="Cuotas"
                                    id="cuotas"
                                    name="cuotas" 
                                    margin="normal"
                                    value={moldaValues.cuotas}   
                                    onChange={handleChange} 
                                />
                            </Grid> 
                            <Grid item xs={12} md={6}>                  
                                <Autocomplete
                                    id="tna"
                                    name="tna" 
                                    options={props.tnaList}   
                                    disableClearable={true}
                                    getOptionLabel={option => {return (`${option.percentage}`)}}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    value={idTna}          
                                    onChange={(event, newValue) => {
                                                setIdTna(newValue);
                                                setFormValues({
                                                        ...moldaValues,
                                                        'tnaId': newValue.id,
                                                        'tna': newValue.percentage,
                                                    });
                                                }}                       
                                    renderInput={params => <TextField className={classes.dateSelect} {...params} label="TNA%" />}
                                />                                           
                            </Grid>
                            <Grid item xs={12} md={6}>  
                                <TextField
                                    label="Score"
                                    id="score"
                                    name="score" 
                                    margin="normal"     
                                    value={moldaValues.score} 
                                    onChange={handleChange}                
                                />
                            </Grid>  
                            <Grid item xs={12} md={6}>  
                                <TextField
                                    label="Deudor"
                                    id="deudor"
                                    name="deudor" 
                                    margin="normal" 
                                    value={moldaValues.deudor} 
                                    onChange={handleChange}                       
                                />
                            </Grid>                    
                        </Grid>
                    <Box my={3} py={3} px={3} css={{ textTransform: 'uppercase',border:'2px solid grey', borderRadius:'25px' }}>
                        <Box display="flex" justifyContent="center" pb={3}>
                            <Typography variant="h4">IMÁGENES</Typography> 
                        </Box> 
                        <Box display="flex" justifyContent="center"  >
                            <Button
                                name='frontal'
                                id='frontal'
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                startIcon={<AccountBoxIcon />}
                                disabled={moldaValues.img[0] !== undefined ? false : true}
                                onClick={() => handleOpen('frontal')}
                            >
                                FONTAL
                            </Button> 
                            <Box px={2}>              
                                <Button
                                    name='posterior'
                                    id='posterior'
                                    variant="contained"
                                    color="secondary"
                                    className={classes.button}
                                    startIcon={<AccountBoxIcon />}
                                    disabled={moldaValues.img[1] !== undefined ? false : true}
                                    onClick={() => handleOpen('posterior')}
                                >
                                    POSTERIOR
                                </Button> 
                            </Box>                     
                            <Button
                                name='secondary'
                                id='selfie'
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                                startIcon={<AccountBoxIcon />}
                                disabled={moldaValues.img[2] !== undefined ? false : true}
                                onClick={() => handleOpen('selfie')}
                            >
                                SELFI
                            </Button> 
                            
                        </Box> 
                    </Box>
                    <Box display="flex" justifyContent="center" pt={4} pb={2}> 
                        <Box pr={1} > 
                            <ActButton                        
                                onClickFunction={handleActualizar}
                                name={'Actualizar'}
                            />
                        </Box>
                        <Box px={1}>
                            <Approvalbutton 
                                onClickFunction={handleAprobar}
                                name={'Aprobar'}                      
                            />                                        
                        </Box>
                        <Box pl={1} >
                            <CancelButton 
                                name={'Denegar'}
                                onClickFunction={handleDenegar}                        
                            />                                    
                        </Box> 
                    </Box> 
                </FormControl>                  
            </Box>
        </div>
    </div>
    </Box>
    );
})

export default ModalPendiente;
