import React, { useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { useSelector } from 'react-redux';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CloseButton from '../../../../../material/Close-Button/CloseButton';
import Modal from '@material-ui/core/Modal';
import ModalImg from './ModalImg';

  const getModalStyle = () => {
    const top = 50 ;
    const left = 50 ;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      
    };
  }

  const useStyles = makeStyles(theme => ({
     paper: {
      position: 'absolute',
      width: 900,  
      height: '90%',    
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    modalStyle:{
        height:'100%',
    },
  }));

const SimpleModal = React.forwardRef ((props, ref) => {
    //DECLARATIONS
    const classes = useStyles();
    const { Close, formValues} = props;
    //LOCAL STATE
    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(false);
    const [imgModal, setImgModal] = useState('');
    const [moldaValues, setFormValues] = useState({
        id: formValues.id,
        userId: formValues.userId,
        tnaId: formValues.interesId,
        nombre: formValues.nombre,
        apellido: formValues.apellido,
        dni: formValues.dni,
        telefono: formValues.telefono,  
        direccion: formValues.direccion,
        cbu: formValues.cbu,                                               
        email: formValues.email,                                                                                                                                                     
        prestamo: formValues.prestamo,
        cuotas: formValues.cuotas,
        tna: formValues.tna,
        score: formValues.score,
        deudor: formValues.deudor,
        img:[],
    });
    //HOOKS
    const arrayImg = useSelector(state => state.admin.arrayImg)
    
    useEffect(() => {        

        //CARGA LAS IMAGENES SI TUVIERE UNA VARIABLE
        let arry = [];
        arrayImg.forEach(element => {
            if (element.url.includes('front')){
                let str = element.url.split("\\");
                arry[0] = str[str.length-1];
            }else if(element.url.includes('back')){
                let str = element.url.split("\\");
                arry[1] = str[str.length-1];;
            }else if(element.url.includes('selfie')){
                let str = element.url.split("\\");
                arry[2] = str[str.length-1];;
            }
        });
        //AGREGA EL NOMBRE DE IMAGENES AL ESTADO DEL COMPONENTE  
        setFormValues({
            ...moldaValues,
            'img': arry,
        });

      }, [arrayImg])
     
    const handleClose = () => {
        setOpen(false);
    };
  
    const handleOpen = (nombre) => {
        //SETEA EN EL ESTADO 
        if(nombre === 'frontal'){
            setImgModal(moldaValues.img[0]);
        } else if(nombre === 'posterior'){
            setImgModal(moldaValues.img[1]);
        } else{
            setImgModal(moldaValues.img[2]);
        }  
        setOpen(true);
    };  


    return(
    <Box >  
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={handleClose}
            className={classes.modalStyle}
        >   
            <ModalImg 
                Close={() =>handleClose()}  
                imgModal={imgModal}                  
            />
        </Modal>  
        <div style={modalStyle} className={classes.paper}> 
        <div style={{overflowY: 'scroll', height:'100%'}}>
            <Box display="flex" flexDirection="row" justifyContent="flex-end" >
                <CloseButton
                    name={''}
                    onClickFunction={Close}
                />
            </Box>  
            <Box textAlign='center'>       
                <FormControl className={classes.formControl}>
                    <Typography variant="h5">DATOS DEL PRESTAMO</Typography>  
                <Grid container>
                    <Grid item xs={12} md={6}>         
                        <TextField
                            label="Nombre"
                            id="nombre"
                            name="nombre" 
                            margin="normal"  
                            disabled                     
                            value={moldaValues.nombre}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>   
                        <TextField
                            label="Apellido"
                            id="apellido"
                            name="apellido" 
                            margin="normal" 
                            disabled                       
                            value={moldaValues.apellido}
                        />
                    </Grid>  
                    <Grid item xs={12} md={6}> 
                        <TextField
                            label="DNI"
                            id="dni"
                            name="dni" 
                            margin="normal"
                            disabled                        
                            value={moldaValues.dni}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}> 
                        <TextField
                            label="Teléfono"
                            id="telefono"
                            name="telefono" 
                            margin="normal"
                            disabled
                            value={moldaValues.telefono}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}> 
                        <TextField
                            label="Dirección"
                            id="direccion"
                            name="direccion" 
                            margin="normal"  
                            disabled      
                            value={moldaValues.direccion}
                        />
                    </Grid>  
                    <Grid item xs={12} md={6}>  
                        <TextField
                            label="CBU"
                            id="cbu"
                            name="cbu" 
                            margin="normal"
                            disabled                       
                            value={moldaValues.cbu}
                        />
                    </Grid>  
                    <Grid item xs={12} md={6}>  
                        <TextField
                            label="Email"
                            id="email"
                            name="email" 
                            margin="normal"       
                            disabled                
                            value={moldaValues.email}   
                        />
                    </Grid> 
                    <Grid item xs={12} md={6}>  
                        <TextField
                            label="Préstamo"
                            id="prestamo"
                            name="prestamo" 
                            margin="normal"
                            disabled
                            value={moldaValues.prestamo}
                        />
                    </Grid> 
                    <Grid item xs={12} md={6}>  
                        <TextField
                            label="Cuotas"
                            id="cuotas"
                            name="cuotas" 
                            margin="normal"
                            disabled
                            value={moldaValues.cuotas}   
                        />
                    </Grid> 
                    <Grid item xs={12} md={6}>                  
                    <TextField
                            label="Tna"
                            id="tna"
                            name="tna" 
                            margin="normal"
                            disabled
                            value={moldaValues.tna}   
                        />                                
                    </Grid>
                    <Grid item xs={12} md={6}>  
                        <TextField
                            label="Score"
                            id="score"
                            name="score" 
                            margin="normal"     
                            value={moldaValues.score} 
                        />
                    </Grid>  
                    <Grid item xs={12} md={6}>  
                        <TextField
                            label="Deudor"
                            id="deudor"
                            name="deudor" 
                            margin="normal" 
                            value={moldaValues.deudor} 
                        />
                    </Grid>                    
                </Grid>
                <Box my={3} py={3} px={3} css={{ textTransform: 'uppercase',border:'2px solid grey', borderRadius:'25px' }}>
                <Box display="flex" justifyContent="center" pb={3}>
                    <Typography variant="h4">IMÁGENES</Typography> 
                </Box> 
                <Box display="flex" justifyContent="center"  >
                    
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        startIcon={<AccountBoxIcon />}
                        disabled={moldaValues.img[0] !== undefined ? false : true}
                        onClick={() => handleOpen('frontal')}
                    >
                        FONTAL
                    </Button> 
                     <Box px={2}>              
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.button}
                            startIcon={<AccountBoxIcon />}
                            disabled={moldaValues.img[1] !== undefined ? false : true}
                            onClick={() => handleOpen('posterior')}
                        >
                            POSTERIOR
                        </Button> 
                    </Box>                     
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        startIcon={<AccountBoxIcon />}
                        disabled={moldaValues.img[2] !== undefined ? false : true}
                        onClick={() => handleOpen('selfie')}
                    >
                        SELFI
                    </Button> 
                    
                     </Box> 
                </Box>
                </FormControl>                  
            </Box>
            </div>
        </div>
    </Box>
    );
})

export default SimpleModal;
