import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import "./styles.scss";
import Logo from '../../img/logo.jpg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

  logo: {
    [theme.breakpoints.up('xs')]: {
      paddingLeft:theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft :theme.spacing(6),
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft:theme.spacing(7),
    },
  },
}));


const NavBar = () => {
const classes = useStyles();

  return(
    <>
    <Navbar
      collapseOnSelect
      expand="lg"
      className="container-navbar"
      variant="dark"
    >
      <div className={classes.logo}>
        <img 
            src={Logo} 
            height='42px' 
            width='250px' 
            alt='logo'
        />  
      </div>
      
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="navbar-right">
        <Nav className="nav">
          <Nav.Link className="items-navbar" href="/calculadora-prestamo">
            Inicio
          </Nav.Link>
          <Nav.Link className="items-navbar" eventKey={2} href="/generador-de-solicitud">
            Pedir prestamo
          </Nav.Link>
          <Nav.Link className="items-navbar" eventKey={2} href="http://www.triacambio.com.ar">
            Tria Cambio
          </Nav.Link>
          <Nav.Link className="items-navbar" eventKey={2} href="/preguntas-frecuentes">
            Preguntas frecuentes
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </>
  )
}


export default NavBar;
