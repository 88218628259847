import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table'
import ModalPendiente from './ModalPendiente';
import ModalError from '../../../ModalError';
import ModalMensaje from '../../../ModalMensaje';
import { useSelector, useDispatch } from 'react-redux';
import palete from '../../../../../../theme/palette';
import {setStateLoan, getUserImg, setErrorClear, setErrorMessage, getPrestamoByStatus} from '../../../../../../store/actions/adminActions'

  const useStyles = makeStyles(theme => ({
    denegar:{
        backgroundColor : '#9a0036',
        borderColor: '#9a0036',
        color:'#ffffff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#dc004e',
            borderColor: '#dc004e',
            boxShadow: 'none',
          },
    },
    aprobar:{
        backgroundColor : '#388e3c',
        borderColor: '#388e3c',
        color:'#ffffff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#4caf50',
            borderColor: '#4caf50',
            boxShadow: 'none',
          },
    },
    actualizar:{
        backgroundColor : '#1976d2',
        borderColor: '#1976d2',
        color:'#ffffff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#2196f3',
            borderColor: '#2196f3',
            boxShadow: 'none',
          },
    },
    modalStyle:{
        height:'100%',
      },
  }));

const Pendientes = (props) =>{
    //DECLARATIONS
    const classes = useStyles();
    const tableRef = React.useRef();
    const { tableIcons } = props;
    //LOCAL STATE
    const [open, setOpen] = useState(false);
    const [ modalRow, setmodalRow] = useState({
        id:null,
        userId:null,
        nombre:null, 
        apellido:null,
        dni:null,
        telefono:null,  
        direccion:null,
        cbu:null,                                               
        email:null,                                                                                                                                                     
        prestamo: null,
        cuotas:null,
        tna:null,
        score: null,
        deudor: null,
    });        
    //HOOKS
    const dispatch = useDispatch();
    const tnaList = useSelector(state => state.admin.tna);
    const errorModal = useSelector(state => state.admin.error);
    const mensajeModal = useSelector(state => state.admin.mensaje);
    const caja = useSelector(state => state.admin.caja)
      
    function refreshTable() {
        tableRef.current && tableRef.current.onQueryChange()
    }
    
    const handleAprobar =  (event, rowData) => {
        if(caja > rowData.amount){
            const payload = {
                id:rowData.id,
                amount: rowData.amount,
                payments: rowData.payments,
                interestId: rowData.interestId,
                status:"APROBADO",                      
                user: {  
                  id:rowData.userId,    
                  dni: rowData.dni,
                  first_name: rowData.first_name, 
                  last_name: rowData.last_name,
                  address: rowData.address,
                  phone_number: rowData.phone_number, 
                  province: 'Buenos Aires', 
                  city: 'Mar del Plata',                    
                  email: rowData.email, 
                  cbu: rowData.cbu,            
                  score: rowData.score,
                  debtor: "NO",   
                  } 
                }
             dispatch(setStateLoan(payload, refreshTable))
        }else{
            dispatch(setErrorMessage(true));
        }
    } 
    
    const handleAprobarModal =  (payload) => {
        if(caja > payload.amount){
             dispatch(setStateLoan(payload, refreshTable))
        }else{
            dispatch(setErrorMessage(true));
        }
    }    
    
    const handleOpen = async (event, rowData) => {
        dispatch(getUserImg(rowData.id));
        setmodalRow({
            id: rowData.id,
            userId: rowData.userId,
            interestId: rowData.interestId,
            nombre: rowData.first_name, 
            apellido: rowData.last_name,
            dni: rowData.dni,
            telefono: rowData.phone_number,  
            direccion: rowData.address,
            cbu:rowData.cbu,                                               
            email:rowData.email,                                                                                                                                                     
            prestamo: rowData.amount,
            cuotas: rowData.payments,
            tna: rowData.percentage,
            score: rowData.score,
            deudor: rowData.debtor,
        })  
        setOpen(true);
    };

    const handleDenegar =  (event, rowData) => {
        const payload = {
            id:rowData.id,
            amount: rowData.amount,
            payments: rowData.payments,
            interestId: rowData.interestId,
            status:"DENEGADO",                      
            user: {  
              id:rowData.userId,    
              dni: rowData.dni,
              first_name: rowData.first_name, 
              last_name: rowData.last_name,
              address: rowData.address,
              phone_number: rowData.phone_number, 
              province: 'Buenos Aires', 
              city: 'Mar del Plata',                    
              email: rowData.email, 
              cbu: rowData.cbu,            
              score: rowData.score,
              debtor: "NO",   
              } 
            }    
        dispatch(setStateLoan(payload, refreshTable))
    }

    const handleDenegarModal =  (payload) => {
        dispatch(setStateLoan(payload, refreshTable))
    }

    const handleClose = () => {
      setOpen(false);
    };
    
return(
    <Box >   
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={handleClose}
            className={classes.modalStyle}
        >   
            <ModalPendiente 
                formValues={modalRow} 
                tnaList={tnaList} 
                Close={() =>handleClose()}
                Aprobar={handleAprobarModal}
                Denegar={handleDenegarModal}
                Actualizar={handleAprobarModal}
                refreshTable={refreshTable}   
                ref={tableRef}           
            />
        </Modal>

        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={errorModal}
            onClose={() => dispatch(setErrorClear()) }
            className={classes.modalStyle}
        > 
            <ModalError
                Close={() => dispatch(setErrorClear()) }
                ref={tableRef}     
            />
        </Modal> 

        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={mensajeModal}
            onClose={() => dispatch(setErrorMessage(false)) }
            className={classes.modalStyle}
        > 
            <ModalMensaje
                Close={() =>  dispatch(setErrorMessage(false)) }
                mensaje='No hay disponibilidad de fondos para entregar el prestamo'
            />
        </Modal> 

        <MaterialTable
            icons={tableIcons}
            title=''
            tableRef={tableRef}
            localization={{
                pagination: {
                    labelRowsSelect: 'Filas',
                    labelDisplayedRows: ' {from}-{to} de {count}',
                    firstTooltip: 'Primera Página',
                    previousTooltip: 'Página Previa ',
                    nextTooltip: 'Pagina Siguiente',
                    lastTooltip: 'Última Página'
                },
                toolbar: {
                    nRowsSelected: '{0}Filas(s) seleccionadas'
                },
                header: {
                    actions: 'Acciones'
                },
                body: {
                    emptyDataSourceMessage: 'Sin registro para mostrar',
                }
            }}
            options={{
                search: false,
                actionsColumnIndex: -1,      
                exportButton: true,
                filtering: true, 
                cellStyle: {
                    color: palete.secondary.main,
                },
                headerStyle: {
                    color: palete.white,
                    backgroundColor: palete.secondary.main,
                }, 
                filterCellStyle:{
                    fontSize: '10px',
                },  
            }}
            columns={[
                { title: 'Fecha', field: 'date', filtering: false },   
                { title: 'Dni', field: 'dni'},
                { title: 'Nombre', field: 'first_name'},
                { title: 'Apellido', field: 'last_name'},
                { title: 'Telefono.', field: 'phone_number', filtering: false},
                { title: 'Monto', field: 'amount', type: 'numeric', filtering: false },
                { title: 'Cuotas', field: 'payments', type: 'numeric' , filtering: false}, 
                { title: 'TNA', field: 'percentage', type: 'numeric' , filtering: false},                                
            ]}
            data={query => 
                new Promise((resolve, reject)  => {
                    dispatch(getPrestamoByStatus('PENDIENTE', query ))
                    .then(res => {
                        resolve({
                            data: res.data.loansList,
                            page: query.page,
                            totalCount: res.data.loanCount,
                            search: query.filters,
                        });    
                    })
                    .catch(
                        err => {
                            console.log(err)
                            return reject
                    })
                })
            }
            actions={[
                    {
                        icon: tableIcons.Check,
                        tooltip: 'Aprobar prestamo',
                        onClick:  (event, rowData) => {
                             handleAprobar(event,rowData);
                        },
                    },
                    {
                        icon: tableIcons.Close,
                        tooltip: 'Denegar prestamo',
                        onClick: (event, rowData) =>  {
                            handleDenegar(event,rowData);
                        }
                    },
                    {
                        icon: tableIcons.Edit,
                        tooltip: 'Editar prestamo',
                        onClick: (event, rowData) => handleOpen(event,rowData),
                    },
                    {
                        icon: tableIcons.Refresh,
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                    }
                ]}              
            /> 
    </Box>
);
};

export default Pendientes;

