import React, {useState} from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import response from '../../config/responses'
import Item from './Item.js'

const Faq2 = () => {
    const [expanded, setExpanded] = useState('');                //false o nombre de panel
    const [ expandeList , setExpandedList]  = useState(''); //false o nombre de listitem 
    
    const handleChange = panel => (event, newExpanded) => { 
        setExpanded(newExpanded ? panel : false);    //false o el nombre del panel
      };
    
      const handleCustom = panel => (event) => {
        if(expandeList === panel){
          setExpandedList(false);
        }else{
          setExpandedList(panel);
        }
      }

return(
    <Box>
        <Box pt={4}>
            <Typography variant="h2" >
                Preguntas Frecuentes
            </Typography> 
        </Box>
        <Box 
            px={{ xs: 2, sm: 3, md: 20, lg: 35, xl:45}}
            pb={10}
            pt={5}
        >

           <ExpansionPanel elevation={0} square expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
            <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">¿Quienes somos?</Typography>
            </ExpansionPanelSummary>
                <ExpansionPanelDetails> 
                    <Box width="95%" margin='auto'> 
                        <List component="nav">
                        {response[0].map((resp,index) =>  
                            <Item
                                key = {index}
                                title = {resp.titulo}
                                Respuesta = {resp.respuesta}  
                                item = {resp.item} 
                                handleCustom = {handleCustom} 
                                expandeList = {expandeList}
                            />
                        )}
                        </List>
                    </Box>
                </ExpansionPanelDetails>        
            </ExpansionPanel>

            <ExpansionPanel elevation={0} square expanded={expanded === 'panel2'} onChange={handleChange('panel2')} >
            <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">Requisitos y calificación</Typography>
            </ExpansionPanelSummary>
                <ExpansionPanelDetails> 
                    <Box width="95%" margin='auto'> 
                        <List component="nav">
                        {response[1].map((resp,index) =>  
                            <Item
                                key = {index}
                                title = {resp.titulo}
                                Respuesta = {resp.respuesta}  
                                item = {resp.item} 
                                handleCustom = {handleCustom} 
                                expandeList = {expandeList}
                            />
                        )} 
                        </List>
                    </Box>
                </ExpansionPanelDetails>        
            </ExpansionPanel>

            <ExpansionPanel elevation={0} square expanded={expanded === 'panel3'} onChange={handleChange('panel3')} >
            <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">Crédito Efectivo</Typography>
            </ExpansionPanelSummary>
                <ExpansionPanelDetails> 
                    <Box width="95%" margin='auto'> 
                        <List component="nav">
                        {response[2].map((resp,index) =>  
                            <Item
                                key = {index}
                                title = {resp.titulo}
                                Respuesta = {resp.respuesta}  
                                item = {resp.item} 
                                handleCustom = {handleCustom} 
                                expandeList = {expandeList}
                            />
                        )} 
                        </List>
                    </Box>
                </ExpansionPanelDetails>        
            </ExpansionPanel>

            <ExpansionPanel elevation={0} square expanded={expanded === 'panel4'} onChange={handleChange('panel4')} >
            <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">Registro</Typography>
            </ExpansionPanelSummary>
                <ExpansionPanelDetails> 
                    <Box width="95%" margin='auto'> 
                        <List component="nav">
                        {response[3].map((resp,index) =>  
                            <Item
                                key = {index}
                                title = {resp.titulo}
                                Respuesta = {resp.respuesta}  
                                item = {resp.item} 
                                handleCustom = {handleCustom} 
                                expandeList = {expandeList}
                            />
                        )} 
                        </List>
                    </Box>               
                </ExpansionPanelDetails>        
            </ExpansionPanel>

            <ExpansionPanel elevation={0} square expanded={expanded === 'panel5'} onChange={handleChange('panel5')} >
            <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h3">Solicitud</Typography>
            </ExpansionPanelSummary>
                <ExpansionPanelDetails> 
                    <Box width="95%" margin='auto'> 
                        <List component="nav">
                        {response[4].map((resp,index) =>  
                            <Item
                                key = {index}
                                title = {resp.titulo}
                                Respuesta = {resp.respuesta}  
                                item = {resp.item} 
                                handleCustom = {handleCustom} 
                                expandeList = {expandeList}
                            />
                        )} 
                        </List>
                    </Box>         
                </ExpansionPanelDetails>        
            </ExpansionPanel>

            <ExpansionPanel elevation={0} square expanded={expanded === 'panel6'} onChange={handleChange('panel6')} >
            <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
               <Typography variant="h3">Cuotas y pagos</Typography>
            </ExpansionPanelSummary>
                <ExpansionPanelDetails> 
                    <Box width="95%" margin='auto'> 
                        <List component="nav">
                        {response[5].map((resp,index) =>  
                            <Item
                                key = {index}
                                title = {resp.titulo}
                                Respuesta = {resp.respuesta}  
                                item = {resp.item} 
                                handleCustom = {handleCustom} 
                                expandeList = {expandeList}
                            />
                        )} 
                        </List>
                    </Box>        
                </ExpansionPanelDetails>        
            </ExpansionPanel>

            <ExpansionPanel elevation={0} square expanded={expanded === 'panel7'} onChange={handleChange('panel7')} >
            <ExpansionPanelSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h3">Renovación</Typography>
            </ExpansionPanelSummary>
                <ExpansionPanelDetails> 
                    <Box width="95%" margin='auto'> 
                        <List component="nav">
                        {response[6].map((resp,index) =>  
                            <Item
                                key = {index}
                                title = {resp.titulo}
                                Respuesta = {resp.respuesta}  
                                item = {resp.item} 
                                handleCustom = {handleCustom} 
                                expandeList = {expandeList}
                            />
                        )} 
                        </List>
                    </Box>        
                </ExpansionPanelDetails>        
            </ExpansionPanel>

        </Box>
    </Box>
    );
}

export default Faq2;
