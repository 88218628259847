const cards = [
  {
    icon: "assets/house.svg",
    message: "Arreglar tu casa",
    color: "#018C2F"
  },
  {
    icon: "assets/car.svg",
    message: "Reparar tu auto",
    color: "#06B942"
  },
  {
    icon: "assets/avion.svg",
    message: "Viajar",
    color: "#2FBC5E"
  },
  {
    icon: "assets/teeth.svg",
    message: "Prótesis e implantes",
    color: "#2FBC5E"
  },
  {
    icon: "assets/party.svg",
    message: "Organizar tu fiesta",
    color: "#018C2F"
  },
  {
    icon: "assets/proyecto.svg",
    message: "Financiar tu proyecto",
    color: "#06B942"
  },
  {
    icon: "assets/beach.svg",
    message: "Disfrutar tus vacaciones",
    color: "#06B942"
  },
  {
    icon: "assets/sofa.svg",
    message: "Redecorar tu casa",
    color: "#2FBC5E"
  },
  {
    icon: "assets/deuda.svg",
    message: "Date un gusto",
    color: "#018C2F"
  }
];

export default cards;
