import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import palette from '../../theme/palette'
import Box from '@material-ui/core/Box';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailIcon from '@material-ui/icons/Mail';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: '#333',
  },
  main:{
    display:"flex" , 
    flexWrap:"wrap" ,
    flexDirection:"row" ,
    [theme.breakpoints.down('xs')]: {
      justifyContent:"center",
      alignItems:"center",
    },
  },
  button:{
    color: theme.palette.white,
    fontSize: '13px',
    fontWeight: 500,
    padding: 0,
    '&:hover': {
      color: palette.primary.main,
    },
  }
}));

const Header = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box 
        className={classes.main}
        py={1}
      >
          <Box px={2} >
            <Button 
              href="mailto:info@triacambio.com.ar" 
              className={classes.button} 
              startIcon={<MailIcon />}
            >
              info@triaprestamos.com.ar
            </Button>
          </Box>
          <Box px={2}>
            <Button 
              href="https://www.instagram.com/triaprestamos/" 
              className={classes.button}
              startIcon={<InstagramIcon />}
            >
              Instagram
            </Button>
          </Box>
        </Box>
    </div>
)};

export default Header;

  