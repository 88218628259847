import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import MaterialTable from 'material-table'
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import SimpleModal from '../SimpleModal/SimpleModal';
import { useDispatch  } from 'react-redux';
import {getUserImg, getPrestamoByStatus} from '../../../../../../store/actions/adminActions';
import palete from '../../../../../../theme/palette';

  const useStyles = makeStyles({
    modalStyle:{
        height:'100%',
    },
  });

const Denegados = (props) =>{
    //DECLARATIONS
    const { tableIcons } = props;
    const tableRef = React.useRef();
    const classes = useStyles();
    //LOCAL STATE
    const [openDenegado, setOpenDenegado] = useState(false);
    const [ modalRow, setmodalRow] = useState({
        id:null,
        userId:null,
        nombre:null, 
        apellido:null,
        dni:null,
        telefono:null,  
        direccion:null,
        cbu:null,                                               
        email:null,                                                                                                                                                     
        prestamo: null,
        cuotas:null,
        tna:null,
        score: null,
        deudor: null,
    });  
    //HOOKS
    const dispatch = useDispatch();
    
    const handleOpenAprobado = (event, rowData) => {
        dispatch(getUserImg(rowData.id));
        setmodalRow({
            id: rowData.id,
            userId: rowData.userId,
            interestId: rowData.interestId,
            nombre: rowData.first_name, 
            apellido: rowData.last_name,
            dni: rowData.dni,
            telefono: rowData.phone_number,  
            direccion: rowData.address,
            cbu:rowData.cbu,                                               
            email:rowData.email,                                                                                                                                                     
            prestamo: rowData.amount,
            cuotas: rowData.payments,
            tna: rowData.percentage,
            score: rowData.score,
            deudor: rowData.debtor,
        })  
        setOpenDenegado(true);
    };

    const handleCloseAprobado = () => {
        setOpenDenegado(false);
    };

return(
    <Box >     
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={openDenegado}
            onClose={handleCloseAprobado}
            className={classes.modalStyle}
        >
            <SimpleModal
                formValues={modalRow}
                Close={() =>handleCloseAprobado()}
                ref={tableRef} 
            />
        </Modal>                
        <MaterialTable
            icons={tableIcons}
            tableRef={tableRef}
            title=''
            isLoading={props.loading}
            localization={{
                pagination: {
                    labelRowsSelect: 'Filas',
                    labelDisplayedRows: ' {from}-{to} de {count}',
                    firstTooltip: 'Primera Página',
                    previousTooltip: 'Página Previa ',
                    nextTooltip: 'Pagina Siguiente',
                    lastTooltip: 'Última Página'
                },
                toolbar: {
                    nRowsSelected: '{0}Filas(s) seleccionadas'
                },
                header: {
                    actions: 'Acciones'
                },
                body: {
                    emptyDataSourceMessage: 'Sin registro para mostrar',
                }
            }}
            options={{
                search: false,
                actionsColumnIndex: -1,      
                exportButton: true,
                filtering: true,  
                cellStyle: {
                    color: palete.secondary.main,
                },
                headerStyle: {
                    color: palete.white,
                    backgroundColor: palete.secondary.main,
                },   
            }}
            columns={[
                { title: 'Fecha', field: 'date' , filtering: false},   
                { title: 'Dni', field: 'dni'},
                { title: 'Nombre', field: 'first_name'},
                { title: 'Apellido', field: 'last_name'},
                { title: 'Telefono.', field: 'phone_number', filtering: false},
                { title: 'Monto', field: 'amount', type: 'numeric', filtering: false },
                { title: 'Cuotas', field: 'payments', type: 'numeric' , filtering: false}, 
                { title: 'TNA', field: 'percentage', type: 'numeric', filtering: false },                                
            ]}
            data={query => 
                new Promise((resolve, reject)  => {
                     dispatch(getPrestamoByStatus('DENEGADO', query))
                    .then(res => {
                        resolve({
                            data: res.data.loansList,
                            page: query.page,
                            totalCount: res.data.loanCount,
                            search: query.filters,
                        });     
                    })
                    .catch(
                        err => {
                            return reject
                    })
                })
            }     
            actions={[
                {
                    icon: tableIcons.Search,
                    tooltip: 'Informacion detallada',
                    onClick: (event, rowData) => handleOpenAprobado(event, rowData)
                },
            ]}      
        />
</Box>
);
}

export default React.memo(Denegados);