import React, { useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { useDispatch, useSelector } from 'react-redux';
import { updateLoan, setStateLoan } from '../../../../../../store/actions/adminActions';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import Modal from '@material-ui/core/Modal';
import ActButton from '../../../../../material/Act-button/ActButton'
import CancelButton from '../../../../../material/cancel-button/CancelButton'
import CloseButton from '../../../../../material/Close-Button/CloseButton';
import ModalImg from '../SimpleModal/ModalImg';

  const getModalStyle = () => {
    const top = 50 ;
    const left = 50 ;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      
    };
  }

  const useStyles = makeStyles(theme => ({
     paper: {
      position: 'absolute',
      width: 900,      
      height: '90%',  
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    modalStyle:{
        height:'100%',
        overflow:'scroll',       
      },
  }));

const ModalPendiente = React.forwardRef ((props, ref) => {
    //DECLARATIONS
    const classes = useStyles();
    const { Close, formValues, refreshTable } = props;
    //LOCAL STATE 
    const [imgModal, setImgModal] = useState('');
    const [open, setOpen] = useState(false);
    const [modalStyle] = useState(getModalStyle);
    const [moldaValues, setFormValues] = useState({
        id: formValues.id,
        userId: formValues.userId,
        tnaId: formValues.interesId,
        nombre: formValues.nombre,
        apellido: formValues.apellido,
        dni: formValues.dni,
        telefono: formValues.telefono,  
        direccion: formValues.direccion,
        cbu: formValues.cbu,                                               
        email: formValues.email,                                                                                                                                                     
        prestamo: formValues.prestamo,
        cuotas: formValues.cuotas,
        tna: formValues.tna,
        score: formValues.score,
        deudor: formValues.deudor,
        img:[],
    }); 
    //HOOKS
    const dispatch = useDispatch();
    const arrayImg = useSelector(state => state.admin.arrayImg)
    
    useEffect(() => {        
        //CARGA LAS IMAGENES SI TUVIERE
        let arry = [];
        arrayImg.forEach(element => {
            if (element.url.includes('front')){
                arry[0] = element;
            }else if(element.url.includes('back')){
                arry[1] = element;
            }else if(element.url.includes('selfie')){
                arry[2] = element;
            }
        });

        setFormValues({
            ...moldaValues,
            'img': arry,
        });

      }, [arrayImg])
     
    const handleChange = (event) => {         
        setFormValues({
            ...moldaValues,
            [event.target.name]: event.target.value
        });
    };

    const handleActualizar = async () => {       
       const payload = {
        id:moldaValues.id,
        amount: moldaValues.prestamo,
        payments: moldaValues.cuotas,
        interestId: moldaValues.tnaId,                      
        user: {  
          id:moldaValues.userId,    
          dni: moldaValues.dni,
          first_name: moldaValues.nombre, 
          last_name: moldaValues.apellido,
          address: moldaValues.direccion,
          phone_number: moldaValues.telefono, 
          province: 'Buenos Aires', 
          city: 'Mar del Plata',                    
          email: moldaValues.email, 
          cbu: moldaValues.cbu,            
          score: moldaValues.score,
          debtor: moldaValues.deudor,   
          } 
        }
        await dispatch(updateLoan(payload,refreshTable)); 
        Close();
    }

    const handleFinalizar = async() => {
        const payload = {
            id:moldaValues.id,
            amount: moldaValues.prestamo,
            payments: moldaValues.cuotas,
            interestId: moldaValues.tnaId, 
            status:"INCOBRABLE",                        
            user: {  
                id:moldaValues.userId,    
                dni: moldaValues.dni,
                first_name: moldaValues.nombre, 
                last_name: moldaValues.apellido,
                address: moldaValues.direccion,
                phone_number: moldaValues.telefono, 
                province: 'Buenos Aires', 
                city: 'Mar del Plata',                    
                email: moldaValues.email, 
                cbu: moldaValues.cbu,            
                score: moldaValues.score,
                debtor: moldaValues.deudor,   
            } 
           }       
        await dispatch(setStateLoan(payload, refreshTable));
        Close(); 
    }

    const handleClose = () => {
        setOpen(false);
      };
  
    const handleOpen = (nombre) => {
        if(nombre === 'frontal'){
            let str = arrayImg[0].url.split("\\");
            setImgModal(str[str.length-1]) 
        } else if(nombre === 'posterior'){
            let str = arrayImg[1].url.split("\\");
            setImgModal(str[str.length-1]) 
        } else{
            let str = arrayImg[2].url.split("\\");
            setImgModal(str[str.length-1])     
        }  
        setOpen(true);
    };  

    return(
    <Box >  
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={open}
            onClose={handleClose}
            className={classes.modalStyle}
        >   
            <ModalImg 
                Close={() =>handleClose()}  
                imgModal={imgModal}                  
            />          
        </Modal> 
        <div style={modalStyle} className={classes.paper}>
            <div style={{overflowY: 'scroll', height:'100%'}}>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" >
                    <CloseButton
                        name={''}
                        onClickFunction={Close}
                    />
                </Box>      
                <Box textAlign='center'>            
                    <FormControl className={classes.formControl}>
                        <Typography variant="h5">DATOS DEL PRESTAMO</Typography>  
                            <Grid container>
                                <Grid item xs={12} md={6}>         
                                    <TextField
                                        label="Nombre"
                                        id="nombre"
                                        name="nombre" 
                                        margin="normal"                       
                                        value={moldaValues.nombre}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>   
                                    <TextField
                                        label="Apellido"
                                        id="apellido"
                                        name="apellido" 
                                        margin="normal"                        
                                        value={moldaValues.apellido}
                                        onChange={handleChange}
                                    />
                                </Grid>  
                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        label="DNI"
                                        id="dni"
                                        name="dni" 
                                        margin="normal"                        
                                        value={moldaValues.dni}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        label="Telefono"
                                        id="telefono"
                                        name="telefono" 
                                        margin="normal"
                                        value={moldaValues.telefono}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}> 
                                    <TextField
                                        label="Dirección"
                                        id="direccion"
                                        name="direccion" 
                                        margin="normal"        
                                        value={moldaValues.direccion}
                                        onChange={handleChange}
                                    />
                                </Grid>  
                                <Grid item xs={12} md={6}>  
                                    <TextField
                                        label="CBU"
                                        id="cbu"
                                        name="cbu" 
                                        margin="normal"                       
                                        value={moldaValues.cbu}
                                        onChange={handleChange}
                                    />
                                </Grid>  
                                <Grid item xs={12} md={6}>  
                                    <TextField
                                        label="Email"
                                        id="email"
                                        name="email" 
                                        margin="normal"                       
                                        value={moldaValues.email}   
                                        onChange={handleChange}                    
                                    />
                                </Grid> 
                                <Grid item xs={12} md={6}>  
                                    <TextField
                                        label="Prestamo"
                                        id="prestamo"
                                        name="prestamo" 
                                        margin="normal"
                                        disabled
                                        value={moldaValues.prestamo}
                                        onChange={handleChange}     
                                    />
                                </Grid> 
                                <Grid item xs={12} md={6}>  
                                    <TextField
                                        label="Cuotas"
                                        id="cuotas"
                                        name="cuotas" 
                                        margin="normal"
                                        disabled
                                        value={moldaValues.cuotas}   
                                        onChange={handleChange} 
                                    />
                                </Grid> 
                                <Grid item xs={12} md={6}>                  
                                <TextField
                                        label="Tna"
                                        id="tna"
                                        name="tna" 
                                        margin="normal"
                                        disabled
                                        value={moldaValues.tna}   
                                        onChange={handleChange} 
                                    />                                
                                </Grid>
                                <Grid item xs={12} md={6}>  
                                    <TextField
                                        label="Score"
                                        id="score"
                                        name="score" 
                                        margin="normal"     
                                        value={moldaValues.score} 
                                        onChange={handleChange}                
                                    />
                                </Grid>  
                                <Grid item xs={12} md={6}>  
                                    <TextField
                                        label="Deudor"
                                        id="deudor"
                                        name="deudor" 
                                        margin="normal" 
                                        value={moldaValues.deudor} 
                                        onChange={handleChange}                       
                                    />
                                </Grid>                    
                            </Grid>
                            <Box my={3} py={3} px={3} css={{ textTransform: 'uppercase',border:'2px solid grey', borderRadius:'25px' }}>
                                <Box display="flex" justifyContent="center" pb={3}>
                                    <Typography variant="h4">IMAGENES</Typography> 
                                </Box> 
                                <Box display="flex" justifyContent="center"  >
                                    
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        startIcon={<AccountBoxIcon />}
                                        disabled={moldaValues.img[0] !== undefined ? false : true}
                                        onClick={() => handleOpen('frontal')}
                                    >
                                        FONTAL
                                    </Button> 
                                    <Box px={2}>              
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className={classes.button}
                                            startIcon={<AccountBoxIcon />}
                                            disabled={moldaValues.img[1] !== undefined ? false : true}
                                            onClick={() => handleOpen('posterior')}
                                        >
                                            POSTERIOR
                                        </Button> 
                                    </Box>                     
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.button}
                                        startIcon={<AccountBoxIcon />}
                                        disabled={moldaValues.img[2] !== undefined ? false : true}
                                        onClick={() => handleOpen('selfie')}
                                    >
                                        SELFI
                                    </Button> 
                                    
                                </Box> 
                            </Box>           
                                <Box display="flex" justifyContent="center" py={5}> 
                                    <Box pr={1} > 
                                        <ActButton                        
                                            onClickFunction={handleActualizar}
                                            name={'Actualizar'}
                                        />
                                    </Box>  
                                    <Box pr={1} > 
                                        <CancelButton                        
                                            onClickFunction={handleFinalizar}
                                            name={'Finalizar'}
                                        />
                                    </Box>                                 
                                </Box> 
                            </FormControl>                  
                        </Box>
                </div>
            </div>  
        </Box>
    );
})

export default ModalPendiente;

