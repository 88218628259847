import React from "react";
import "./styles.scss";
import Typography from '@material-ui/core/Typography';

const Requisitos = () => (
  <div className="container-all">
    <Typography 
          variant="h1" 
          align="left" 
          gutterBottom
    >
      Sólo necesitás:
    </Typography>
    <div className="container-requisitos">
      <span>
        <i className="fas fa-check icon-check"></i>DNI
      </span>
      <span>
        <i className="fas fa-check icon-check"></i>CBU
      </span>
      <span>
        <i className="fas fa-check icon-check"></i>12 meses de antigüedad
      </span>
      <span>
        <i className="fas fa-check icon-check"></i>Último recibo de sueldo
      </span>
    </div>
  </div>
);

export default Requisitos;


// import React from "react";
// import "./styles.scss";
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '80%',
//     alignItems:'center',
//   },
  
// }));

// const Requisitos = () => {
//   const classes = useStyles();

// return (
//     <Box 
//       mx="auto"
//       width= '80%' 
//     >
//       <Typography 
//             variant="h1" 
//             align="left" 
//             gutterBottom
//       >
//         Sólo necesitás:
//       </Typography>
//       <div className="container-requisitos">
//         <span>
//           <i className="fas fa-check icon-check"></i>DNI
//         </span>
//         <span>
//           <i className="fas fa-check icon-check"></i>CBU
//         </span>
//         <span>
//           <i className="fas fa-check icon-check"></i>12 meses de antigüedad
//         </span>
//         <span>
//           <i className="fas fa-check icon-check"></i>Último recibo de sueldo
//         </span>
//       </div>
//     </Box>
// );

// }
// export default Requisitos;
