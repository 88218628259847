import React, {Fragment, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import MontoYcuotas from '../step-form/MontoYcuotas';
import InfoPersonal from '../step-form/InfoPersonal';
import UploadPhoto from '../step-form/UploadPhoto';
import Resumen from '../step-form/Resumen';
import {colors} from '../../colors';

const useStyles = makeStyles(theme => ({
  layout: {
    width: 'auto',
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.up(1000 + theme.spacing(2) * 2)]: {
      width: 850,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    [theme.breakpoints.up('xs')]: {
      padding:theme.spacing(5),
    },
    [theme.breakpoints.up('md')]: {
      padding:theme.spacing(6),
    },
    [theme.breakpoints.up('lg')]: {
      padding:theme.spacing(6),
    },
    borderStyle:'solid',
    border:5,
    borderTopLeftRadius: 80,
    borderBottomRightRadius: 80,
    borderColor:colors.greentria, //modificar
  },
  stepper: {
    padding: theme.spacing(8, 0, 3, 0),
    background: 'none',
  },
  buttons:{
    marginTop: theme.spacing(8)
  }
}));

const steps = ['MONTO Y CUOTAS', 'DATOS PERSONALES', 'VERIFICAR IDENTIDAD'];


const StepForm = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = (step) => {
    setActiveStep(step);
  };

  const getStepContent= (step) => {
    switch (step) {
      case 0:
        return <MontoYcuotas handleNext={handleNext}/>;
      case 1:
        return <InfoPersonal handleNext={handleNext}/>;                     
      case 2:
        return <UploadPhoto  handleNext={handleNext}/>;
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <Fragment>
      <CssBaseline />
      <div className={classes.layout}>
      <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        <div className={classes.paper}>
          <Fragment>
            {activeStep === steps.length ? (
              <Fragment>
                <Resumen handleBack={handleBack} />
              </Fragment>
            ) : (
              <Fragment>
                {getStepContent(activeStep)}                
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
}

export default StepForm;



