import React, {useState, useEffect} from 'react';
import Modal from '@material-ui/core/Modal';
import { useSelector, useDispatch} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
//import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import CloseButton from '../../../../../material/Close-Button/CloseButton';
//import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import DateFnsUtils from '@date-io/date-fns';
import { setPayments, setStateLoan, getLoanPayments, cleanPayment, setErrorMessage} from '../../../../../../store/actions/adminActions';
import LinearProgress from '@material-ui/core/LinearProgress';
import ModalMensaje from '../../../ModalMensaje';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';


const getModalStyle = () => {
    const top = 50 ;
    const left = 50 ;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      
    };
  }

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: 900,      
        height: '90%',  
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
      },
      modalStyle:{
          height:'100%',
        },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
        maxWidth: 300,
      },
    loading:{
        width:'100%',       
    },
    
  }));

const ModalPagos = React.forwardRef ((props, ref) => {
    //DECLARATIONS
    const classes = useStyles();
    const { Close, Payment, refreshTable } = props;
    //LOCAL STATE
    //const [taxSelected, setTaxSelected] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [modalStyle] = useState(getModalStyle);
    const [ pago, setPago] = useState({
        userId: '',
        dni: '',
        nombre: '',
        apellido: '',
        payment_id: '',
        loan_id:'',
        maxDate:'',
        status:'PAGADO',
        totalPrestamo:'',
        totalCuotas: '',
        trueFee: '',
        owedDays: '',
        owedAmount: '',
        iva: '',
        cuota: '',
        percentage: '',
        paymentDate: '',
    }); 
    //HOOKS
    const mensajeModal = useSelector(state => state.admin.mensaje);
    const dispatch = useDispatch();
    //const Taxes = useSelector(state => state.admin.taxes)  
   
    useEffect(() => { 

        if(Payment !== undefined){
            setPago({
                userId: Payment.loan.user.id,
                dni: Payment.loan.user.dni,
                nombre: Payment.loan.user.first_name,
                apellido: Payment.loan.user.last_name,
                payment_id: Payment.id,
                loan_id: Payment.loan.id,
                status:'PAGADO',
                maxDate: Payment.maxPaymentDate,
                totalPrestamo: Payment.loan.amount,
                totalCuotas: Payment.loan.payments,
                trueFee:  Payment.owedAmount !== null ?  (Payment.totalFee + Payment.owedAmount) : Payment.totalFee,
                owedDays: Payment.owedDays,
                owedAmount: Payment.owedAmount,
                iva: Payment.owedIva !== undefined  ? (Payment.iva + Payment.owedIva) : Payment.iva,
                cuota: Payment.feeNumber,
                percentage: Payment.interestId,   
                paymentDate: new Date(), 
            })}
       }, [Payment])

    const handleChange = (event) => { 
        setPago({
            ...pago,
            [event.target.name]: event.target.value
        });
    };

    const handleDateChange = date => {
        const loan_id = pago.loan_id;
        setSelectedDate(date);
        dispatch(cleanPayment());
        dispatch(getLoanPayments(loan_id, date));
    };

    // const handleChangeMultiple = event => {
    //     const { options } = event.target;
    //     const value = [];
    //     for (let i = 0, l = options.length; i < l; i += 1) {
    //       if (options[i].selected) {
    //         value.push(options[i].value);
    //       }
    //     }
    //     setTaxSelected(value);
    // };

    const handlePayment = async() => {
        if(Payment.totalFee <= parseFloat(pago.trueFee)){
            dispatch(setPayments(pago));
            Close();
        }else{
            dispatch(setErrorMessage(true));
        }
        if(Payment.feeNumber === Payment.loan.payments){
            const payload = {
                id:pago.loan_id,
                status:"PAGADO",
                user: {
                    id:pago.userId,
                }
            }
            await dispatch(setStateLoan(payload,refreshTable));
            Close()
        }
    }
     
    const form = Payment === undefined ?
        <Box  >
            <Box className={classes.loading}>  {/* MODIFICAR ESTO*/ }
                <Box pt={4 }pb={4}>
                    <LinearProgress/>
                </Box>
                <Box pb={4}>
                    <LinearProgress/>
                </Box>
                <Box pb={4}>
                    <LinearProgress/>
                </Box>
                <Box pb={4}>
                    <LinearProgress/>
                </Box>
            </Box>
              
        </Box>
    : 
    <Box>   
        <Box display="flex" flexDirection="row" justifyContent="flex-end" >
            <CloseButton
                name={''}
                onClickFunction={Close}
            />
        </Box> 
        <Grid container alignItems="center">
            <Grid item xs={12} md={6}>         
                <TextField
                    label="Nombre"
                    id="nombre"
                    name="nombre" 
                    margin="normal"
                    value={pago.nombre}
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>         
                <TextField
                    label="Apellido"
                    id="apellido"
                    name="apellido" 
                    margin="normal"
                    value={pago.apellido}
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}> 
                <TextField
                    label="DNI"
                    id="dni"
                    name="dni" 
                    margin="normal"
                    value={pago.dni}
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={6}>  
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="fpago"
                        label="Fecha de pago"
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider>
            </Grid> 
            <Grid item xs={12} md={6}> 
                <TextField
                    label="Cantidad de cuotas"
                    id="totalCuotas"
                    name="totalCuotas" 
                    margin="normal"
                    value={pago.totalCuotas}
                />
            </Grid>

           <Grid item xs={12} md={6}>  
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="fmax"
                        label="Fecha Máxima"
                        value={Payment.maxPaymentDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        disabled
                    />
                </MuiPickersUtilsProvider>
            </Grid>              

            <Grid item xs={12} md={6}> 
                <TextField
                    label="Cuota a pagar"
                    id="cuotaActual"
                    name="cuotaActual" 
                    margin="normal"
                    value={pago.cuota}
                />
            </Grid>
            
            <Grid item xs={12} md={12}>
                <Box py={3}>
                    <Divider/>
                </Box>
            </Grid>
            
            <Grid item xs={12} md={6}>                                  
                <InputLabel htmlFor="cuota">Monto a Pagar</InputLabel>                                       
                    <OutlinedInput
                        fullWidth={true}
                        id="trueFee"   
                        name='trueFee'                                                                                         
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                        labelWidth={60}
                        value={Math.round(pago.trueFee * 100) / 100 }
                        onChange={handleChange}
                    />                                         
            </Grid>

            {/* <Grid item xs={12} md={6}>   
            <Box  textAlign='center'>
                <FormControl className={classes.formControl}>  
                    <InputLabel shrink htmlFor="select-multiple-native">
                        Impuestos
                    </InputLabel>
                        <Select
                            multiple
                            native
                            value={taxSelected}
                            onChange={handleChangeMultiple}
                            inputProps={{
                                id: 'select-multiple-native',
                            }}
                        >
                            {Taxes.map(name => (
                                <option key={name.id} value={name.type}>
                                    {name.type}
                                </option>
                            ))}
                        </Select> 
                </FormControl>  
            </Box> 
            </Grid> */}
            <Grid item xs={12} md={12}>
                <Box py={3}>
                    <Divider/>  
                </Box>
            </Grid>                         
        </Grid>                    
        <Box py={3} px={3} css={{ textTransform: 'uppercase',border:'2px solid grey', borderRadius:'25px' }}>
            <Grid container>
                <Grid  item xs={12} md={6}>
                    <Typography variant="subtitle1">Cuota Total: </Typography>
                </Grid>
                <Grid  item xs={12} md={6}>
                    <Typography variant="subtitle2">${Payment === undefined ? 0 : Math.round(Payment.totalFee * 100) / 100 }</Typography>
                </Grid>

                <Grid  item xs={12} md={6}>
                    <Typography variant="subtitle1">Cuota Pura: </Typography>
                </Grid>
                <Grid  item xs={12} md={6}>
                    <Typography variant="subtitle2">${Payment === undefined ? 0 : Math.round(Payment.pureFee * 100) / 100 }</Typography>
                </Grid>

                <Grid  item xs={12} md={6}>
                    <Typography variant="subtitle1">Capital: </Typography>
                </Grid>
                <Grid  item xs={12} md={6}>
                    <Typography variant="subtitle2">${Payment === undefined ? 0 : Math.round(Payment.capital * 100) / 100 }</Typography>
                </Grid>

                <Grid  item xs={12} md={6}>
                    <Typography variant="subtitle1">Interes: </Typography>
                </Grid>
                <Grid  item xs={12} md={6}>
                    <Typography variant="subtitle2">${Payment === undefined ? 0 : Math.round(Payment.interest * 100) / 100 }</Typography>
                </Grid>

                
                <Grid  item xs={12} md={6}>
                    <Typography variant="subtitle1">Gastos Administrativos: </Typography>
                </Grid>
                <Grid  item xs={12} md={6}>
                    <Typography variant="subtitle2">${Payment === undefined ? 0 : Math.round(Payment.admExpenses * 100) / 100 }</Typography>
                </Grid>

                <Grid  item xs={12} md={6}>
                    <Typography variant="subtitle1">IVA: </Typography>
                </Grid>
                <Grid  item xs={12} md={6}>
                    <Typography variant="subtitle2">${ Payment !== undefined && Payment.owedIva !== undefined ? Math.round((Payment.iva + Payment.owedIva) * 100) / 100 
                                                       : Payment !== undefined && Payment.owedIva === undefined ? Math.round(Payment.iva * 100) / 100 
                                                       : 0 } 
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Días de mora</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle2">{ pago.owedDays === null ? 0 : pago.owedDays }</Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle1">Total Intereses Aplicados</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="subtitle2">${ pago.owedAmount === null ? 0 : Math.round(Payment.owedAmount * 100) / 100}</Typography>
                </Grid>

            </Grid>                                
        </Box> 
        <Box display="flex" justifyContent="center" pt={5} >
            <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handlePayment} 
                >
                    Ingresar Pago
            </Button>                        
        </Box>   
    </Box> 
    ;
    return(

        <Box>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={mensajeModal}
                onClose={() => dispatch(setErrorMessage(false)) }
                className={classes.modalStyle}
            > 
                <ModalMensaje
                    Close={() =>  dispatch(setErrorMessage(false)) }
                    mensaje='El monto ingresado no puede ser menor a la cuota establecida'
                />
            </Modal>
            <div style={modalStyle} className={classes.paper}>
                <div style={{overflowY: 'scroll', height:'100%'}}>
                    {form}
                </div>
            </div>
            
        </Box>
        
    );
})


export default ModalPagos;