import React, { useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {getImg} from '../../../../../../store/actions/adminActions'
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import CloseButton from '../../../../../material/Close-Button/CloseButton';

const arrayBufferToBase64 = (buffer) =>{
  var binary = '';
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => binary += String.fromCharCode(b));
  return window.btoa(binary);
};

const getModalStyle = () => {
    const top = 50 ;
    const left = 50 ;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
}

const BackgroundHead = {
  height: '100%',
  width:'100%',
}

  const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: 900,      
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
    
      padding: theme.spacing(2, 4, 3),
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
      },
    modalStyle:{
        height:'100%',
        overflow:'scroll',       
      },
  }));
  
  const ModalImg = React.forwardRef ((props, ref) => {
    //DECLARATIONS
    const { Close, imgModal } = props
    const classes = useStyles();
    //LOCAL STATE
    const [modalStyle] = useState(getModalStyle);
    const [img, setImg] = useState(getModalStyle);
    //HOOKS
    const dispatch = useDispatch();

    useEffect( () => { 
      async function fetchData() {
        if(imgModal !== undefined && imgModal !== null){
          const response = await dispatch(getImg(imgModal))
          let reader = new FileReader()
          reader.onload = function() {
            setImg(reader.result)
          }
          reader.readAsDataURL(response.data)
        }
      }
    fetchData();
    },[imgModal])

    return(
        <div style={modalStyle} className={classes.paper}>
          <Box display="flex" flexDirection="row" justifyContent="flex-end" >
                <CloseButton
                    name={''}
                    onClickFunction={Close}
                />
          </Box>   
          <div style={BackgroundHead}>
            <img 
              src={img} 
              height='100%' 
              width='100%' 
              alt='asd'
            /> 
          </div>
        </div>
    );

  })

  export default ModalImg;
