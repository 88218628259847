import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme'
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import WebFont from 'webfontloader';
import user from './store/reducers/reducer'
import admin from './store/reducers/adminReducer'

WebFont.load({
  google: {
    families: ['Raleway', 'sans-serif']
  }
});

const redu = combineReducers({
  user: user,
  admin: admin,
})

const store = createStore(
    redu,
    composeWithDevTools(applyMiddleware(thunk))
  );


  
ReactDOM.render(
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </Provider>
                , document.getElementById('root'));
serviceWorker.unregister();




