import React from "react";


const NotFound = () =>{
    return (
        <div>
            no encontrado
        </div>
    );
}

export default NotFound;