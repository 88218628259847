import React,{ useState} from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import ApprovalButton from '../../../material/Approval-button/ApprovalButton'
import TextField from '@material-ui/core/TextField';
import useForm from '../../../hooks/useForm';
import InputAdornment from '@material-ui/core/InputAdornment';
import { removeCapital, setErrorMessage } from '../../../../store/actions/adminActions';
import { useDispatch } from 'react-redux';

    const getModalStyle = () => {
        const top = 50 ;
        const left = 50 ;
    
        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
    };
  }
  
  const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: 600,      
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },  modalStyle:{
        height:'100%',
        overflow:'scroll',
      },
  
  }));

const ModalEgreso = React.forwardRef ((props, ref) => {
    const [modalStyle] = useState(getModalStyle);
    const classes = useStyles();
    const dispatch = useDispatch();  
    const { Close , Caja, Capital} = props;

    const [ valores ] = useState(0);

    const {inputs, handleInputChange, inputsErrors} = useForm({valores},{valores:''});

    const handleIngreso = (event) =>{
      event.preventDefault(); 
      if(Capital >= parseInt(inputs.valores) && Caja >= parseInt(inputs.valores)){
        dispatch(removeCapital(inputs.valores));
      }else{
        dispatch(setErrorMessage(true));
      }
      Close();
    }

return(
  <form onSubmit={handleIngreso} autoComplete="off">          
      <div 
        style={modalStyle} 
        className={classes.paper}
      >  
        <Box display="flex">
          <Box width="75%" py={1}>
            <Typography 
              variant="subtitle2" 
            >  
              INGRESE VALORES A RETIRAR
            </Typography>
          </Box>
          <Box 
            width="20%"
            textAlign='right'
          >
            <IconButton 
              aria-label="close" 
              onClick={ Close }
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box py={1}>
          <Divider />  
        </Box> 
            <Box pt={5} pb={3}>
              <TextField 
                  id="valores" 
                  name="valores"
                  variant="outlined" 
                  InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  onChange={handleInputChange} 
                  value={inputs.valores}
                  helperText={inputsErrors.valores} 
                  onBlur={handleInputChange} 
                  error={inputsErrors.valores !== '' ? true : false}
                  required
              />
            </Box> 
            <Box 
                pt={3} 
                textAlign='right'
              >
                <ApprovalButton
                    name='aceptar'
                >
                    Aceptar
                </ApprovalButton>
              </Box>   
      </div>
  </form>  
);
})


export default ModalEgreso;