import React, { useState,forwardRef  } from 'react';
import Box from '@material-ui/core/Box';
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SaveIcon from '@material-ui/icons/Save';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { updateTax , AddTax, setErrorClear} from '../../../../../../store/actions/adminActions';
import { useDispatch, useSelector} from 'react-redux';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import ModalError from '../../../ModalError'
//import useForm from '../../../../../hooks/useForm';

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <EditOutlinedIcon {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Save: forwardRef((props, ref) => <SaveIcon {...props} ref={ref} />),
    Close: forwardRef((props, ref) => <CloseOutlinedIcon {...props} ref={ref} />),
    Money: forwardRef((props, ref) => <MonetizationOnOutlinedIcon {...props} ref={ref} />),
};

const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: 900,      
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    modalStyle:{
        height:'100%',
        overflow:'scroll',       
      },
}));

const Impuestos = (props) =>{
    const dispatch = useDispatch();
    const classes = useStyles();
    const errorModal = useSelector(state => state.admin.error);
    //{ title: 'Nombre', field: 'type', editable: 'onAdd' },

    //const {inputs, handleInputChange, inputsErrors} = useForm({valores:0},{valores:''});

    const [ colImpuestos  ] = useState([
        { title: 'Nombre', field: 'type', editable: 'onAdd' },
        { title: 'Interes', field: 'percentage'},
        //     editComponent: props => (
            
        //         <TextField
        //             id="valores" 
        //             name="valores"
        //             variant="outlined" 
        //             InputProps={{
        //                 startAdornment: <InputAdornment position="start">%</InputAdornment>,
        //             }}
        //             onChange={handleInputChange} 
        //             value={inputs.valores}
        //             helperText={inputsErrors.valores} 
        //             onBlur={handleInputChange} 
        //             error={inputsErrors.valores !== '' ? true : false}
        //             required
        //         />
           
        //     )
        // },
    ]);

return(
    <Box>  
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={errorModal}
            onClose={() => dispatch(setErrorClear()) }
            className={classes.modalStyle}
            > 
                <ModalError
                    Close={() => dispatch(setErrorClear()) }
                />
        </Modal> 
        <Box pt={2}>
            <MaterialTable
                icons={tableIcons}  
                title=''
                options={{
                    search: true,
                    actionsColumnIndex: -1,      
                    exportButton: true, 
                    pageSize:5, 
                    pageSizeOptions: [5, 10, 100],         
                }}         
                columns={colImpuestos}            
                data={props.ListTaxes}
                editable={{                                       
                    onRowUpdate: (newData, oldData) =>{                             
                        let num = parseFloat(newData.percentage);
                        if(!isNaN(num)){
                            return(
                                new Promise((resolve, reject) => {                                   
                                    dispatch(updateTax(newData))
                                    .then(() => {
                                        resolve();                                                                                                      
                                    })
                                    .catch(err => {
                                        reject();                                                                 
                                    })
                                })
                            );              
                            }else{
                                return(
                                    new Promise((resolve, reject) => {                                                                                                
                                        reject();
                                    })
                                );                            
                        }
                    }, 
                    onRowAdd: newData =>{
                        let num = parseFloat(newData.percentage);
                        if(!isNaN(num)){
                            return(
                                new Promise((resolve, reject) => {                                   
                                    dispatch(AddTax(newData))
                                    .then(() => {
                                        resolve();                                                                                                    
                                    })
                                    .catch(err => {
                                        reject();                                                                 
                                    })

                                })
                            );              
                            }else{
                                return(
                                    new Promise((resolve, reject) => {                                                                                                
                                        reject();
                                    })
                                );                            
                        }                    
                    },                                                        
                }}
            />
        </Box> 
    </Box>
);
}

export default React.memo(Impuestos);
