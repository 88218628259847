import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import {colors} from '../../../colors.js'

export const CustomSlider = withStyles(theme => ({
    root: {
      color: colors.greentria,
      height: 30,
      width: '100%',
      fontWeight:900,
      //paddingTop: 30,
    },
    thumb: {
      height: 24,
      width: 24,
      border: '2px solid currentColor',
      marginTop: -10,
      marginLeft: -12,
      '&:focus,&:hover,&$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 11px)',
        top: -22,
        '& *': {
          background: 'transparent',
          color: colors.greentria,
          fontSize: 18,
        },
      },
    track: {
      height: 3,
      borderRadius: 4,
    },
    rail: {
      height: 1,
      borderRadius: 4,
    },
    markLabel:{
        fontSize: 18,
        marginTop: 2,
    },
    }))(Slider);
  