import React from 'react'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';


const useStyles = makeStyles(theme => ({
    aprobar:{
        backgroundColor : '#388e3c',
        borderColor: '#388e3c',
        color:'#ffffff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#4caf50',
            borderColor: '#4caf50',
            boxShadow: 'none',
          },
    },

  }));
 
const Approvalbutton = (props) => {
const classes = useStyles();
return(
        <Button 
            variant="contained" 
            className={classes.aprobar} 
            size="large"
            startIcon={<Check />}                            
            display="inline-block" 
            onClick={props.onClickFunction}
            type="submit"
        >
            {props.name}
        </Button>
)
}


export default Approvalbutton;