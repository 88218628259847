import React,{useEffect,useState} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Interes from './components/Interes/Interes';
import Impuestos from './components/Impuestos/Impuestos'
import {useSelector, useDispatch} from 'react-redux';
import { getTna } from '../../../../store/actions/adminActions'

const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

const Formulas = (props) => {
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {      
      dispatch(getTna())

    }, [])

    const ListTna = useSelector(state => state.admin.tna);
    const ListTaxes = useSelector(state => state.admin.taxes);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

return (
    <Box >          
        <Box >
        <AppBar position="static">
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Intereses" {...a11yProps(0)} /> 
                <Tab label="Impuestos" {...a11yProps(0)} />               
            </Tabs>
        </AppBar>                    
            <TabPanel value={value} index={0}>
                <Interes 
                  ListTna={ListTna} 
                />
            </TabPanel>  
            <TabPanel value={value} index={1}>
                <Impuestos 
                  ListTaxes={ListTaxes} 
                />
            </TabPanel>          
        </Box>             
    </Box>
    
); 
}

export default React.memo(Formulas);



        
        
  