import React, {Fragment} from 'react';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const Item = (props) => {
  
return(
       <Fragment>
            <ListItem 
              button 
              expanded="false" 
              onClick={props.handleCustom(props.item)} 
            >         
              <ListItemText primary={props.title} />
                {props.expandeList === props.item ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse 
              in={ props.expandeList === props.item } 
              timeout="auto" 
              unmountOnExit
            >
              <List 
                component="div"                 
              >
                <ListItem mx={3}>             
                  <Typography variant="subtitle2">
                    {props.Respuesta}
                  </Typography>
                </ListItem>
              </List>
         </Collapse>
    </Fragment>
    );
}

export default Item;