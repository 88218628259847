import React,{ useState} from 'react';
import Box from '@material-ui/core/Box';
import MaterialTable from 'material-table';
import { setTna, getTna, setErrorClear } from '../../../../../../../store/actions/adminActions';
import { useDispatch, useSelector} from 'react-redux';
import Modal from '@material-ui/core/Modal';
import ModalError from '../../../../ModalError';
import { makeStyles } from '@material-ui/core/styles';
import palete from '../../../../../../../theme/palette';

const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: 900,      
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
    
      padding: theme.spacing(2, 4, 3),
    },
    modalStyle:{
        height:'100%',
        overflow:'scroll',       
      },
  }));

const Tna = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const tableRef = React.useRef();
    const [ colTna ] = useState([
        { title: 'Cuotas', field: 'fee', editable: 'never' },
        { title: 'TNA ANUAL', field: 'percentage'},
    ]);

    const {tableIcons} = props;
    const errorModal = useSelector(state => state.admin.error);

    return(
        <Box>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={errorModal}
                onClose={() => dispatch(setErrorClear()) }
                className={classes.modalStyle}
            > 
                <ModalError
                    Close={() => dispatch(setErrorClear()) }
                />
            </Modal> 
        
            <Box>
            <MaterialTable
                icons={tableIcons}  
                title='TNA'
                tableRef={tableRef}
                localization={{
                    pagination: {
                        labelRowsSelect: 'Filas',
                        labelDisplayedRows: ' {from}-{to} de {count}',
                        firstTooltip: 'Primera Página',
                        previousTooltip: 'Página Previa ',
                        nextTooltip: 'Pagina Siguiente',
                        lastTooltip: 'Última Página'
                    },
                    toolbar: {
                        nRowsSelected: '{0}Filas(s) seleccionadas'
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    body: {
                        emptyDataSourceMessage: 'Sin registro para mostrar',
                    }
                }}
                options={{
                    search: false,
                    actionsColumnIndex: -1,      
                    exportButton: true, 
                    cellStyle: {
                        color: palete.secondary.main,
                    },
                    headerStyle: {
                        color: palete.white,
                        backgroundColor: palete.secondary.main,
                    },   
                }}      
                columns={colTna}            
                data={query => 
                    new Promise( (resolve, reject)  => {
                        dispatch(getTna())
                        .then(res =>{
                            const newArrayData =  res.data.tnaList.slice(((query.page+1) * query.pageSize)- query.pageSize , (query.page+1)*query.pageSize );
                            resolve({
                                data: newArrayData,
                                page: query.page,
                                totalCount: res.data.tnaList.length,
                                search: query.filters,
                            });       
                        })
                        .catch(
                            err => {
                                return reject
                        })
                    })
                }
                editable={{                                       
                    onRowUpdate: (newData, oldData) =>{                             
                        let num = parseFloat(newData.percentage) 
                        if(!isNaN(num)){
                            let obj = { id: newData.id ,fee: newData.fee , percentage: num };                    
                            return(
                                new Promise((resolve, reject) => {                                   
                                    dispatch(setTna(obj))
                                    .then(() => {
                                        resolve();                                                                                                      
                                    })
                                    .catch(err => {
                                        reject();                                                                 
                                    })
                                })
                            );
                            }else{
                                return(
                                    new Promise((resolve, reject) => {                                                                                                
                                        resolve();
                                    })
                                );                            
                        }
                    },               
                }} 
                actions={[
                    {
                        icon: tableIcons.Refresh,
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                    }
                ]}                                                                          
            />
            </Box> 
        </Box>
    );

}

export default React.memo(Tna);

