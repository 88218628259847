import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { CustomSlider } from '../../material/custom-slider/CustomSlider';
import Box from '@material-ui/core/Box';
import {setChange} from '../../../store/actions/action';
import {useSelector, useDispatch} from 'react-redux';
import {CustomButtom} from '../../material/custom-button/CustomButtom';
import Grid from '@material-ui/core/Grid';

const valueLabelFormat= (value) => {
  return (`$${value}`);
}

const MontoYcuotas = (props) => {
  const [ label, setLabel ] = useState(0);
  const dispatch = useDispatch();
  const tna = useSelector(state => state.user.tna);
  const cuotas = useSelector(state => state.user.cuotas);
  const credito = useSelector(state => state.user.credito);
  const cuotaMin = useSelector(state => state.user.cuotaMin);
  const cuotaMax = useSelector(state => state.user.cuotaMax);
  const creditoMin = useSelector(state => state.user.creditoMin);
  const creditoMax = useSelector(state => state.user.creditoMax);

  useEffect(() => {
    if (tna !== null){
      const select = tna.find(array => array.fee === cuotas);
      const feeTna = (((select.percentage)/12)/100);
      const cuotaPura = credito * ((Math.pow(1+feeTna,cuotas)*feeTna)/(Math.pow(1+feeTna,cuotas)-1));
      const interesCuota = ( credito * feeTna );
      const capital = ( cuotaPura - interesCuota );
      const ivaCuota = interesCuota * 0.21; 
      setLabel((ivaCuota+capital+interesCuota).toFixed(2));
    }
  },  [cuotas, credito,tna])

  return (
    <Box textAlign='left' >
      <Box pb={1}>
        <Typography 
          variant="h2" 
          align="left" 
          gutterBottom
          
        >
          Seleccione Monto
        </Typography>
      </Box>
      <CustomSlider
        aria-label="money slider"
        value={credito}
        max={creditoMax}
        min={creditoMin}
        valueLabelDisplay='on'
        getAriaValueText={valueLabelFormat}
        valueLabelFormat={valueLabelFormat}
        onChange={(e, data) => dispatch(setChange('credito',data))} 
        step={500}
        marks={[{value: creditoMin, label: `${creditoMin}`},{value: creditoMax, label: `${creditoMax}`}]}
      />
      <Box pb={1}>
        <Typography 
          variant="h2" 
          align="left"
          gutterBottom
        >
          Seleccione cuotas
        </Typography>
      </Box>
      <CustomSlider
        aria-label="dues slider"
        value={cuotas}
        max={cuotaMax}
        min={cuotaMin}
        valueLabelDisplay='on'
        onChange={(e, data) => dispatch(setChange('cuotas',data))} 
        marks={[{value: cuotaMin, label: `${cuotaMin}`},{value: cuotaMax, label: `${cuotaMax}` }]}
      />
      <Typography
        variant="h5" 
        align="left"
        gutterBottom
      >
        Cuota por mes 
      </Typography>
      <Typography variant="h2" align="left">
        ${label}
      </Typography>
      <Grid item xs={12}>
        <Box textAlign='center' pt={5} >
          <CustomButtom
            variant="contained"
            color="primary"
            onClick={props.handleNext}
            >
              Siguiente
          </CustomButtom>            
        </Box> 
      </Grid> 
    </Box>
  );
}

export default MontoYcuotas;


