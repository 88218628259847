
export default {
    root:{
        startIcon:{
            marginRigth:1,
            marginLeft:1
        },  
    },
    
};


