import React,{useState} from 'react';
import Box from '@material-ui/core/Box';
import MaterialTable from 'material-table';
import { useDispatch, useSelector} from 'react-redux';
import { setCuotas , setErrorClear, getCuotas} from '../../../../../../../store/actions/adminActions';
import ModalError from '../../../../ModalError';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import palete from '../../../../../../../theme/palette';

const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      width: 900,      
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
    
      padding: theme.spacing(2, 4, 3),
    },
    modalStyle:{
        height:'100%',
        overflow:'scroll',       
      },
  }));
  
const Cuotas = (props) => {
const tableRef = React.useRef();
 //DECLARATIONS
 const classes = useStyles();
 const { tableIcons } = props;
 //LOCAL STATE
 const [ col ] = useState([
    { title: 'Cuota Minima', field: 'minValue'},
    { title: 'Cuota Maxima', field: 'maxValue'},
]);   
 //HOOKS
const dispatch = useDispatch();
const errorModal = useSelector(state => state.admin.error);

    return(
        <Box>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={errorModal}
                onClose={() => dispatch(setErrorClear()) }
                className={classes.modalStyle}
            > 
                <ModalError
                    Close={() => dispatch(setErrorClear()) }
                />
            </Modal> 
        
            <Box pt={2}>
            <MaterialTable
                icons={tableIcons}  
                title='Cuotas'
                tableRef={tableRef}
                localization={{
                    pagination: {
                        labelRowsSelect: 'Filas',
                        labelDisplayedRows: ' {from}-{to} de {count}',
                        firstTooltip: 'Primera Página',
                        previousTooltip: 'Página Previa ',
                        nextTooltip: 'Pagina Siguiente',
                        lastTooltip: 'Última Página'
                    },
                    toolbar: {
                        nRowsSelected: '{0}Filas(s) seleccionadas'
                    },
                    header: {
                        actions: 'Acciones'
                    },
                    body: {
                        emptyDataSourceMessage: 'Sin registro para mostrar',
                    }
                }}
                options={{
                    search: false,
                    actionsColumnIndex: -1,
                    exportButton: true,
                    pageSize:1,
                    pageSizeOptions: [1],  
                    cellStyle: {
                        color: palete.secondary.main,
                    },
                    headerStyle: {
                        color: palete.white,
                        backgroundColor: palete.secondary.main,
                    },   
                }}
                columns={col}            
                data={query => 
                    new Promise( (resolve, reject)  => {
                        dispatch(getCuotas())
                        .then(res =>{
                            resolve({
                                data: [res.data.configsList[0]],
                                page: 0,
                                totalCount: 1,
                            });       
                        })
                        .catch(
                            err => {
                                return reject
                        })
                    })
                }
                editable={{                                       
                    onRowUpdate: (newData, oldData) =>{  
                        let id = newData.id;
                        let min = parseFloat(newData.minValue)         
                        let max = parseFloat(newData.maxValue) 
                        if(!isNaN(min) && !isNaN(max) ){
                            let obj = {id:id ,minValue:min ,maxValue:max };    
                            return(
                                new Promise((resolve, reject) => {                                   
                                    dispatch(setCuotas(obj))
                                    .then(() => {
                                        resolve();                                                                                                      
                                    })
                                    .catch(err => {
                                        reject();                                                                 
                                    })
                                })
                            );                                            
                        }else{
                            return(
                                new Promise((resolve, reject) => {                                                                                                
                                    reject();
                                })
                            );
                        }
                    }
                }}
                actions={[
                    {
                        icon: tableIcons.Refresh,
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                    }
                ]}                                                        
            />
            </Box> 
        </Box>
    );

}

export default React.memo(Cuotas);
  