import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { colors } from '../../../colors.js'

export const CustomButtom = withStyles({
    root: {
      boxShadow: 'none',
      textTransform: 'none',
      fontSize: 16,
      padding: '6px 12px',
      border: '1px solid',
      borderRadius: '25px 0px 25px 0px',
      lineHeight: 1.5,
      backgroundColor: colors.greentria,
      borderColor: colors.greentria,
      margin:10,
      height:50,
      size:'large',
      fontFamily: [
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        backgroundColor: colors.greenbgcard,
        borderColor: colors.greenbgcard,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: colors.greenbgcard,
        borderColor: colors.greenbgcard,
      },
      '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
      },
    },
    label:{
       marginLeft : 60,
       marginRight: 60,  
    }
  })(Button);


