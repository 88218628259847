import React, {useState, useEffect,forwardRef} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Aprobados from './components/Aprobados/Aprobados';
import Todos from './components/Todos/Todos';
import Denegados from './components/Denegados/Denegados';
import Pendientes from './components/Pendientes/Pendientes';
import Pagados from './components/Pagados/Pagados'
import Finalizados from './components/Finalizados/Finalizados';
import { useDispatch} from 'react-redux';
import { getTna, getCaja} from '../../../../store/actions/adminActions';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import SaveIcon from '@material-ui/icons/Save';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <EditOutlinedIcon {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  Save: forwardRef((props, ref) => <SaveIcon {...props} ref={ref} />),
  Close: forwardRef((props, ref) => <CloseOutlinedIcon {...props} ref={ref} />),
  Pdf: forwardRef((props, ref) => <PictureAsPdfIcon {...props} ref={ref} />),
  Refresh: forwardRef((props, ref) => <RefreshIcon {...props} ref={ref} />),
  Money: forwardRef((props, ref) => <AttachMoneyIcon {...props} ref={ref} />),
};

const TabPanel = (props) => {  
    const { children, value, index, ...other } = props;
    
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  
const Prestamos = (props) => {
    const [value, setValue] = useState(0);
    const dispatch = useDispatch();  

    useEffect(() => {      
      dispatch(getTna());
      dispatch(getCaja());
    }, [dispatch])
    
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    
return (
    <Box >
        <Box >
          <AppBar position="static">
              <Tabs 
                value={value} 
                onChange={handleChange} 
                aria-label="simple tabs example"
              >
                  <Tab label="Pendientes" {...a11yProps(0)} />
                  <Tab label="Aprobados" {...a11yProps(1)} />
                  <Tab label="Denegados" {...a11yProps(2)} />
                  <Tab label="Finalizados" {...a11yProps(3)} />
                  <Tab label="Pagados" {...a11yProps(4)} />
                  <Tab label="Todos" {...a11yProps(5)} />
              </Tabs>
          </AppBar>          
          <TabPanel value={value} index={0}>
              <Pendientes tableIcons={tableIcons} />            
          </TabPanel>
          <TabPanel value={value} index={1}>
              <Aprobados tableIcons={tableIcons} />
          </TabPanel>
          <TabPanel value={value} index={2}>
              <Denegados tableIcons={tableIcons} />
          </TabPanel>
          <TabPanel value={value} index={3}>
              <Finalizados tableIcons={tableIcons} />
          </TabPanel>
          <TabPanel value={value} index={4}>
              <Pagados tableIcons={tableIcons} />
          </TabPanel>
          <TabPanel value={value} index={5}>
              <Todos tableIcons={tableIcons} />
          </TabPanel>
        </Box>     
    </Box>
); 
}

export default Prestamos;