import React,{Fragment} from 'react'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
const useStyles = makeStyles(theme => ({

    denegar:{
        backgroundColor : '#9a0036',
        borderColor: '#9a0036',
        color:'#ffffff',
        border: '1px solid',
        '&:hover': {
            backgroundColor: '#dc004e',
            borderColor: '#dc004e',
            boxShadow: 'none',
          },
      
        //startIcon
    },

  }));


const CancelButton = (props) => {
const classes = useStyles();
return(
    <Fragment>
        <Button 
            variant="contained" 
            className={classes.denegar} 
            size="small"
            startIcon={<CloseOutlinedIcon />}                             
            display="inline-block" 
            onClick={props.onClickFunction}
        >
            {props.name}
        </Button>
    </Fragment> 
)
}

export default CancelButton;
