const responses = [
        [ //0
            {
                id: "1",
                titulo: "1. ¿Qué es TRIAPRESTAMOS?",
                respuesta: "Somos una entidad cambiaria que además brinda el servicio de préstamos  personales de manera 100% online. Nuestro objetivo es brindar soluciones rápidas y efectivas a las necesidades de las personas. Contamos con un proceso enteramente online, sin perder la calidad humana y la respuesta empática hacia quienes se acercan a nosotros. Nuestras respuestas son inmediatas y nuestros procesos simples, nos enfocamos en mejorar y crecer constantemente para brindar un excelente servicio. Garantizamos transparencia,  sinceridad y solvencia a todos quienes quieran sumarse a la experiencia TRIAPRESTAMOS. ",
                item: "panel1list1",
            },
            {
                id: "2",
                titulo: "2. ¿Cómo puedo pedir un Crédito en TRIAPRESTAMOS? ",
                respuesta: "El proceso se hace enteramente en nuestra página de internet: Primero debés ingresar tus datos en el formulario de registro, y finalmente solicitar el Crédito que te interesa siguiendo los pasos que te indicamos en nuestra web.",
                item: "panel1list2",
            },
            {
                id: "3",
                titulo: "3. ¿Cuál es el horario de atención de TRIAPRESTAMOS?",
                respuesta: "Nuestro horario de atención es de lunes a viernes de 9.30 hs a 16.00 hs. Nuestra página funciona las 24 hs del día los 365 días del año. Podés hacer la solicitud de tu Crédito en cualquier momento!",
                item: "panel1list3",
            },
            {
                id: "4",
                titulo: "4. ¿Cuáles son los medios de contacto de TRIAPRESTAMOS?",
                respuesta: "Podés contactarte con nosotros a través de WhatsApp al 2235463409, correo electrónico info@triaprestamos.com.ar, y en nuestra página de Instagram. O bien podés enviarnos tu consulta a través de nuestra página web, haciendo click en “Escribinos”. ",
                item: "panel1list4",
            },
        ],//1
        [
            {
                id: "5",
                titulo: "5. ¿Qué requisitos necesito para pedir un Crédito en TRIAPRESTAMOS?",
                respuesta: "Los requisitos son: tener una caja de ahorro activa a tu nombre, tener un mínimo historial crediticio, poder demostrar ingresos mensuales, con una antigüedad no menor a 12 meses y no tener antecedentes crediticios negativos en ese lapso y poseer un servicio donde conste el domicilio declarado",
                item: "panel2list1",
            },
            {
                id: "6",
                titulo: "6. ¿Cómo sé si califico para un Crédito?",
                respuesta: "Una vez que te hayas registrado, podés ingresar a tu cuenta y realizar la solicitud de tu Crédito. Realizaremos un primer análisis crediticio a través de nuestra página.",
                item: "panel2list2",
            },
            {
                id: "7",
                titulo: "7. ¿Cuál es el monto máximo que puedo pedir, con qué intereses y en qué plazos?",
                respuesta: "El monto máximo de Crédito, la cantidad de cuotas y la tasa de interés están definidos por el perfil de cada cliente. Esto es determinado por un análisis crediticio que realizamos cuando hacés la solicitud.",
                item: "panel2list3",
            },
            {
                id: "8",
                titulo: "8. Estoy en el Veraz, ¿puedo hacer la solicitud?",
                respuesta: "Somos una entidad cambiaria que además brinda el servicio de préstamos  personales de manera 100% online. Nuestro objetivo es brindar soluciones rápidas y efectivas a las necesidades de las personas. Contamos con un proceso enteramente online, sin perder la calidad humana y la respuesta empática hacia quienes se acercan a nosotros. Nuestras respuestas son inmediatas y nuestros procesos simples, nos enfocamos en mejorar y crecer constantemente para brindar un excelente servicio. Garantizamos transparencia,  sinceridad y solvencia a todos quienes quieran sumarse a la experiencia TRIAPRESTAMOS. ",
                item: "panel2list4",
            },
            {
                id: "9",
                titulo: "9. ¿Puedo solicitar un Crédito siendo jubilado/a, pensionado/a o cobrando asignación",
                respuesta: "No es impedimento para realizar la solicitud! Es importante que tengas una cuenta para poder depositarte el crédito y realizar el cobro de las cuotas mensuales.",
                item: "panel2list5",
            },
            {
                id: "10",
                titulo: "10. Me dicen que no hay crédito disponible para mi ¿Qué significa? ",
                respuesta: "Si la página te mostró un mensaje que dice “Lo lamentamos, por el momento no tenemos Crédito para ofrecerte”, quiere decir que tu perfil crediticio y de riesgo no califica para nuestros productos en este momento. Podés volver a intentarlo más adelante!",
                item: "panel2list6",
            },
        ],
        [//2
            {
                id: "11",
                titulo: "11. ¿Qué montos otorgan? ",
                respuesta: "Otorgamos desde $10.000 hasta $100.000. ",
                item: "panel3list1",
            },
            {
                id: "12",
                titulo: "12. ¿Para qué tengo que usar el dinero?",
                respuesta: "El Crédito que te otorgamos no tiene un fin específico ni ningún tipo de limitación: podés usar el dinero para lo que vos quieras!",
                item: "panel3list2",
            },
            {
                id: "13",
                titulo: "13. ¿Si pido un Crédito fuera del horario bancario, cuando se acreditan los fondos en mi cuenta?",
                respuesta: "El Crédito lo transferimos en pocos minutos a tu cuenta los días hábiles entre las 9.00hs y las 16.00hs. Todos los créditos que se otorguen fuera de ese horario serán transferidos en el transcurso de la mañana siguiente. La velocidad de acreditación dependerá también del banco receptor de los fondos.",
                item: "panel3list3",
            },
            {
                id: "14",
                titulo: "14. ¿En qué se diferencia TRIAPRESTAMOS de los bancos tradicionales? ",
                respuesta: "Nosotros somos simples y eficientes: cero papeles, cero demoras, cero gastos; con ninguno de nuestros productos pagás seguros. Además, podés usar la caja de ahorro que ya tenés sin necesidad de crear una nueva para disfrutar de nuestros servicios.",
                item: "panel3list4",
            },
        ],//3
        [
            {
                id: "15",
                titulo: "15. ¿Cómo creo mi usuario?",
                respuesta: "Para registrarte como usuario, tenés que ingresar tus datos en el formulario de registro que se encuentra en nuestra página. Es necesario que pongas tu nombre y apellido exactamente como figuran en el DNI, tu sexo, tu número de DNI sin los puntos, y una dirección de correo válida a la que tengas acceso",
                item: "panel4list1",
            },
            {
                id: "16",
                titulo: "16. ¿Puedo registrarme aunque no tenga correo electrónico?",
                respuesta: "Somos una entidad cambiaria que además brinda el servicio de préstamos  personales de manera 100% online. Nuestro objetivo es brindar soluciones rápidas y efectivas a las necesidades de las personas. Contamos con un proceso enteramente online, sin perder la calidad humana y la respuesta empática hacia quienes se acercan a nosotros. Nuestras respuestas son inmediatas y nuestros procesos simples, nos enfocamos en mejorar y crecer constantemente para brindar un excelente servicio. Garantizamos transparencia,  sinceridad y solvencia a todos quienes quieran sumarse a la experiencia TRIAPRESTAMOS. ",
                item: "panel4list2",
            },
            {
                id: "17",
                titulo: "17. ¿Qué hago si ingresé mal algún dato al registrarme? ",
                respuesta: "Tener una cuenta de mail es un requisito indispensable para tener un usuario TRIAPRESTAMOS y acceder a unCrédito, porque es la vía de contacto primordial con nuestros clientes y es a donde enviaremos la confirmación de tu solicitud, los contratos y la información de tus cuotas.",
                item: "panel4list3",
            },
            {
                id: "18",
                titulo: "18. ¿Es seguro completar mis datos en TRIAPRESTAMOS?",
                respuesta: "Nuestra web se maneja con los más altos estándares de privacidad y los datos de nuestros clientes son protegidos de acuerdo con la Ley 25.326 de Protección de los Datos Personales. Para mayor seguridad, recordá siempre comunicarte con nosotros a través de los medios oficiales de contacto que estipulamos en nuestra página web.",
                item: "panel4list4",
            },
        ],
        [ //4
            {
                id: "19",
                titulo: "19. ¿Cómo comienzo la solicitud? ",
                respuesta: "Una vez ingresas en nuestra página, podés comenzar con la solicitud. Podrás ver el calculador de tu crédito, el mismo te indica el monto máximo y cantidad de cuotas máximas que podés pedir. Esto está definido por un análisis crediticio hecho sobre tu DNI.",
                item: "panel5list1",
            },
            {
                id: "20",
                titulo: "20. ¿Qué datos me piden?",
                respuesta: "Al aceptar los montos que te ofrecemos, te pediremos un número telefónico de contacto, y tu domicilio. Una vez completado esto, te pediremos tu CBU, tu CUIT, y que cargues las fotos del frente de tu DNI, del dorso de tu DNI, una selfie tuya con tu DNI en mano y un scan de tu último recibo de sueldo.",
                item: "panel5list2",
            },
            {
                id: "21",
                titulo: "21. No puedo cargar las fotos de mi DNI, ¿qué hago? ",
                respuesta: "¡No te preocupes! Podés omitir ese paso y te las pediremos luego.",
                item: "panel5list3",
            },
            {
                id: "22",
                titulo: "22. ¿Cómo sé si aprobaron mi crédito?",
                respuesta: "Al terminar la solicitud, el crédito queda preaprobado y sujeto a un último análisis crediticio. Nos pondremos en contacto con vos a la brevedad, por vía telefónica al número que dejaste registrado.",
                item: "panel5list4",
            },
            {
                id: "23",
                titulo: "23. ¿Por qué me piden el CBU? ¿Qué pasa si no aprueban mi crédito?",
                respuesta: "Solicitamos tus datos bancarios para agilizar el proceso de solicitud en caso de ser aprobado el crédito. En caso de que la solicitud sea rechazada, los datos son desestimados al no continuar con el proceso.",
                item: "panel5list5",
            },
            {
                id: "24",
                titulo: "24. ¿Puedo ingresar datos bancarios de otra persona?",
                respuesta: "No es posible, ya que los datos indicados deben estar a tu nombre por seguridad.",
                item: "panel5list6",
            },
        ],
        [ //5
            {
                id: "25",
                titulo: "25. ¿Las cuotas son fijas?",
                respuesta: "Las cuotas son mensuales y fijas, el monto no se modifica. Están compuestas por capital, intereses gastos administrativos e impuesto al valor agregado, detallados en nuestra página cuando realizás tu solicitud. Para ver esto, podés hacer click en “Ver detalle de cuotas” al ingresar al simulador de Crédito.",
                item: "panel6list1",
            },  
            {
                id: "26",
                titulo: "26. ¿Dónde puedo ver el estado de mi cuenta?",
                respuesta: "Al momento de confirmarse tu Crédito, te enviamos a tu casilla de mail un correo que contiene adjunto el contrato del Crédito y el detalle de las cuotas.",
                item: "panel6list2",
            },  
            {
                id: "27",
                titulo: "27. ¿Cómo pago mis cuotas?",
                respuesta: "Las cuotas de tu Crédito se debitarán mensualmente de tu Caja de Ahorro en las fechas indicadas en el cronograma de pagos. En caso de no haber podido debitar, el pago será por medio de Pago Fácil. Para eso, te enviaremos un mail a tu casilla indicando que no fue posible debitar la cuota.",
                item: "panel6list3",
            },  
            {
                id: "28",
                titulo: "28. ¿Qué pasa si no tengo fondos cuando me hacen el débito?",
                respuesta: "Hacemos tres intentos de débito en tu cuenta: el primero en la fecha de vencimiento de la cuota y el segundo a los tres días hábiles si el anterior falla. Si en ninguno de los dos intentos se encuentran fondos en la caja de ahorro, se trata una última vez en el mismo plazo, sin punitorios. Si estos tres intentos fallan, la cuota entra en mora y empiezan a computarse intereses compensatorios e intereses punitorios. Para abonar, te enviaremos a tu casilla de mail una boleta para abonar por Pago Fácil.",
                item: "panel6list4",
            },  
            {
                id: "29",
                titulo: "29. ¿Cómo pago mis cuotas en mora?",
                respuesta: "Para abonar las cuotas vencidas se emitirá una boleta de pago en nuestros medios habilitados, que se enviará al mail del usuario; no podrán ser abonadas por débito automático.",
                item: "panel6list5",
            },  
            {
                id: "30",
                titulo: "30. ¿Qué pasa si tengo varios créditos y me atrasé con uno?",
                respuesta: "A diferencia de los préstamos bancarios tradicionales, las cuotas TRIAPRESTAMOS en mora funcionan de manera independiente. Es decir, si tenés una cuota en mora las siguientes cuotas se debitarán de tu cuenta de acuerdo con el cronograma original, mientras que la cuota adeudada sigue sumando intereses punitorios que equivalen a un 50% más de TNA. Las cuotas en mora seguirán acumulando intereses punitorios hasta que sean abonadas.",
                item: "panel6list6",
            },
        ],
        [ //6
            {
                id: "31",
                titulo: "31. Al ser cliente, ¿debo volver a cargar mis datos?",
                respuesta: "¡No es necesario! En caso de tener que actualizar la base de datos, nos comunicaremos con vos telefónicamente.",
                item: "panel7list1",
            },  
            {
                id: "32",
                titulo: "32. ¿Cuál es el monto que puedo solicitar?",
                respuesta: "El monto máximo que tengas disponible va a depender de las cuotas que tengas abonadas en tus créditos vigentes. También por tu perfil crediticio. ",
                item: "panel7list2",
            },  
            {
                id: "33",
                titulo: "33. Rechazaron mi nueva solicitud, ¿por qué sucedió esto? ",
                respuesta: "Cada vez que se realiza una solicitud, realizamos un nuevo análisis crediticio sobre tu perfil. Si tenés mora vigente con otras entidades, cuotas impagas de tu crédito, o pocas cuotas abonadas, lamentablemente el sistema no nos permitirá otorgarte un nuevo crédito.",
                item: "panel7list3",
            },
        ],
    ];
    

export default responses;