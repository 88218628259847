import React from "react";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {calcCredit} from '../../config/functions'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.footer,
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        [theme.breakpoints.up('xs')]: {
             paddingLeft: theme.spacing(2),
             paddingRight: theme.spacing(2),
           },
      },
    font : {
        color: theme.palette.black,
        fontSize: 10,
    },
  }));

const Terms = (props) => { 
    const classes = useStyles();   
    const tna = useSelector(state => state.user.tna);
    const cuotaMin = useSelector(state => state.user.cuotaMin);
    const cuotaMax = useSelector(state => state.user.cuotaMax);
    let resMin = 0;
    let resMax = 0
    let prestamo = 10000;

    if (tna !== null){
        resMax = calcCredit(tna[0].percentage, cuotaMin, prestamo); // TNA MINIMO x lo tanto entrega los resultados mas ALTOS de CFT y TEA
        resMin = calcCredit(tna[(tna.length)-1].percentage, cuotaMax, prestamo); // // TNA MAXIMO x lo tanto entrega los resultados mas BAJOS de CFT y TEA
    }
    
    return(
        <Box className={classes.root}>
            <Box>
                <Typography className={classes.font}>El período mínimo para la devolución de un préstamo es de {cuotaMin} meses, siendo el máximo {cuotaMax} meses. La TNA de un préstamo varía dependiendo del perfil crediticio del solicitante, siendo la mínima {tna !== null ? tna[(tna.length)-1].percentage: ''}% y la máxima {tna !== null ? tna[0].percentage : ''}%. A su vez, la TEA mínima es de {resMin.tea}% siendo la máxima {resMax.tea}%.</Typography>
                <Typography className={classes.font}>CFTEA varía entre {resMax.cftConIva}% y {resMin.cftConIva}%, acorde al nivel de riesgo del solicitante y su correspondiente TNA. Incluye IVA</Typography>
                <Typography className={classes.font}>A modo de ejemplo Monto solicitado de ${prestamo} a {cuotaMin} meses | TNA (sin IVA): { tna !== null ? tna[0].percentage : 0 }% - TEA (sin IVA): {resMax.tea}% - CFTEA (con IVA): {resMax.cftConIva}%  Cuota: ${resMax.valorCuota} | Total a pagar: ${resMax.devTotal}</Typography>
            </Box>
        </Box>
    );
}

export default Terms;
