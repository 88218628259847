import {path} from '../../config/db';
import axios from 'axios';

export const SET_CHANGE = 'SET_CHANGE'; 
export const SET_ENVIO_OK = 'SET_ENVIO_OK'; 
export const SET_ERROR = 'SET_ERROR'; 
export const SET_DEFAULT = 'SET_DEFAULT'

export const ADD_FILE = 'ADD_FILE';
export const DELETE_FILE = 'DELETE_FILE';

export const GET_TNA_USER = 'GET_TNA_USER';
export const GET_CONFIG_USER = 'GET_CONFIG_USER';

    const reciveSetDefault = () => {
        return {
        type: SET_DEFAULT,
        };
    };

    const reciveChange = (fieldName, fieldValue) => {
        return {
        type: SET_CHANGE,
        fieldName,
        fieldValue
        };
    };

    const reciveFile = (file,fileName ) => {
        return {
            type: ADD_FILE,
            file,  
            fileName,          
        };   
    }

    const reciveDelete = (index,foto ) => {
        return {
            type: DELETE_FILE,
            index,    
            foto,      
        };   
    }

    const reciveTna = (tna) => {
        return {
            type: GET_TNA_USER, 
            tna   
        };   
    }

    const reciveConfig = (config) => {
        return {
            type: GET_CONFIG_USER, 
            config   
        };   
    }

    const reciveNewLoan = (state) => {
        return {
            type: SET_ENVIO_OK, 
            state   
        };   
    }

    const setSendError = (state) => {
        return {
            type: SET_ERROR, 
            state   
        };   
    }

    export const setChange = (fieldName, fieldValue) => dispatch => {          
        dispatch(reciveChange(fieldName, fieldValue));    
    };

    export const addFile = (file, fileName) => dispatch => { 
        dispatch(reciveFile(file, fileName));           
    };

    export const deleteFile = (index, foto) => dispatch => {       
        dispatch(reciveDelete(index,foto));           
    };

    export const getInfoUser = () => dispatch => {  
        axios.get(path+'/tnas')
        .then(res => dispatch(reciveTna(res.data.tnaList)))
        .catch(err => console.log(err));
        
        axios.get(path+`/configs`)
        .then(res => {
            dispatch(reciveConfig(res.data.configsList));
        })
        .catch(err => {
            console.log(err)
        })
    }

    export const createLoan = (json, fotos) => dispatch => {
        const array = [];

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };    

        const dni = json.user.dni;
        const d = new Date();
        const year = d.getFullYear();
        let month = d.getMonth()+1;
        const seconds = d.getSeconds();
        const formData = new FormData();
        if(fotos[0].primera !== null){
            var nomPrimera = fotos[0].primera.name;
            nomPrimera = nomPrimera.split('.').pop();
            formData.append(`image`, fotos[0].primera, `dni_front_${dni}_${year}_${month}_${seconds}.${nomPrimera}`);
            array.push('1');
        }
        if(fotos[1].segunda !== null){
            var nomSegunda = fotos[1].segunda.name;
            nomSegunda = nomSegunda.split('.').pop();
            formData.append(`image`, fotos[1].segunda, `dni_back_${dni}_${year}_${month}_${seconds}.${nomSegunda}`);
            array.push('2');
        }
        if(fotos[2].tercera !== null){
            var nomTercera = fotos[2].tercera.name;
            nomTercera = nomTercera.split('.').pop();
            formData.append(`image`, fotos[2].tercera, `dni_selfie_${dni}_${year}_${month}_${seconds}.${nomTercera}`);
            array.push('3');
        }            

        axios.post(path+'/loans', json)
        .then(response => {
            dispatch(reciveNewLoan(true))          
            let userId = response.data.loanResults.userId;
            if(array.length > 0){
                axios.post(path+`/users/image/${userId}`, formData, config)
                .then(response => console.log('img post',response))
                .catch(error => console.log('post img',error))
            }
        })
        .catch(error => {
            dispatch(setSendError(true));
        });

    }

    export const setError = (state) => dispatch => {
        dispatch(setSendError(state));
        dispatch(reciveNewLoan(state));
    }

    export const setDefault = () => dispatch => {
        dispatch(reciveSetDefault());
        dispatch(getInfoUser());
    }

    









