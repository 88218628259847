import {path} from '../../config/db';
import axios from 'axios';

export const LOGIN_ADMIN = 'LOGIN_ADMIN';
export const LOGOUT_ADMIN = 'LOGOUT_ADMIN';
export const ISLOGIN = 'ISLOGIN';

export const GET_TNA = 'GET_TNA';
export const GET_IMG = 'GET_IMG';
export const GET_TAXES = 'GET_TAXES';
export const GET_CAPITAL = 'GET_CAPITAL';
export const GET_CAJA = 'GET_CAJA';
export const GET_CIRCULACION = 'GET_CIRCULACION';
export const GET_VALORESMES = 'GET_VALORESMES';
export const GET_PAYMENT = 'GET_PAYMENT';

export const SET_SEND_ERROR = 'SET_SEND_ERROR';
export const SET_MENSAJE = 'SET_MENSAJE';
export const SET_PAGO = 'SET_PAGO';


    const reciveGetCirculacion = (payments) => {
        return {
            type: GET_CIRCULACION,  
            payments            
        };   
    }

    const reciveGetValoresMes = (obj) => {
        return {
            type: GET_VALORESMES,  
            obj            
        };   
    }

    const reciveGetTna = (tna) => {
        return {
            type: GET_TNA,  
            tna            
        };   
    }

    const reciveLogin = (user) => {
        return {
            type: LOGIN_ADMIN,   
            user            
        };   
    }

    const isLogin = (loading) => {
        return {
            type: ISLOGIN,   
            loading            
        };   
    }

    const reciveLogout = () => {
        return {
            type: LOGOUT_ADMIN,                 
        };   
    }

    const reciveGetCuota = (payment) => {
        return {
            type: GET_PAYMENT,  
            payment               
        };   
    }

    const reciveGetCapitals = (monto) => {
        return {
            type: GET_CAPITAL, 
            monto   
        };   
    }

    const reciveGetCaja = (monto) => {
        return {
            type: GET_CAJA, 
            monto   
        };   
    }

    const reciveMesagge = (mensaje) => {
        return {
            type: SET_MENSAJE, 
            mensaje   
        };   
    }

    const reciveGetTaxes = (taxes) => {
        return {
            type: GET_TAXES, 
            taxes   
        };   
    }
    
    const reciveImg = (array) => {
        return {
            type: GET_IMG, 
            array   
        };   
    } 

    const reciveError = (bool) => {
        return {
            type:  SET_SEND_ERROR,
            err: bool,
        };
       
    }

    export const loginAdmin = (payload) => {
        return dispatch => { 
            //RUTA PULICA NO NECESITA TOKEN
            dispatch(isLogin(true))
            const resp = axios.post(path+'/admins', payload)
            .then(res => {
                localStorage.setItem(
                    'userData',
                    JSON.stringify({
                    userId: res.data.userData.userId,
                    token: res.data.userData.token,
                    })
                );
                dispatch(isLogin(false))
                dispatch(reciveLogin(res.data.userData)); 
                return false 
            }) 
            .catch(err => {
                dispatch(isLogin(false));
                return true
                //console.log(err)
            })
            return resp;
        };
    }

    export const logoutAdmin = () => dispatch => {     
    //SIN LLAMADA A API RESTA ELIMINAR EL TOKEN DEL STORAGE  
        dispatch(reciveLogout());  
        localStorage.removeItem('userData');         
    };

    export const getTna = () => {
        return dispatch => {  
    //RUTA PULICA NO NECESITA TOKEN
            const res = axios.get(path+'/tnas')
            .then(res  => {
                dispatch(reciveGetTna(res.data.tnaList))
                return res;
            })
            .catch(
                err => {
                    return err
            }) 
            return res; 
        }
    }

    export  const  getIva = () => {
        return dispatch => {  
        //RUTA PRIVADA NECESITA TOKEN 
            const userData =  JSON.parse(localStorage.getItem('userData')); 
            const res = axios.get(path+'/iva', {
                headers: { Authorization: "Bearer " + userData.token }})
            .then(res => {
                return res;
            })
            .catch(err => {
                return err;
            })
            return res;
        }
    }

    export  const  getMora = () => {
        return dispatch => {  
        //RUTA PRIVADA NECESITA TOKEN 
            const userData =  JSON.parse(localStorage.getItem('userData')); 
            const res =  axios.get(path+'/owed', {
                headers: { Authorization: "Bearer " + userData.token }})
            .then(res => {
                return res;
            })
            .catch(err => {
                return err;
            })
            return res;
        }
    }

    export  const  getTodos = (query) => { 
        return dispatch => { 
        //RUTA PRIVADA NECESITA TOKEN 
            const userData =  JSON.parse(localStorage.getItem('userData'));           
                const res = axios.get(path+'/loans', {
                    headers: { Authorization: "Bearer " + userData.token},
                    params: { query }
                })
                .then(res =>  {
                    return(res)
                })
                .catch(err => {
                    return err;
            })
            return res;
        }
    }

    export  const  getPrestamoByStatus = (status, query) => {
        return dispatch => {  
        //RUTA PRIVADA NECESITA TOKEN 
            const userData =  JSON.parse(localStorage.getItem('userData')); 
            const res = axios.get(path+`/loans/getByStatus/${status}`, {
                headers: { Authorization: "Bearer " + userData.token }, 
                params: { query }}, 
            )
            .then(res => {  
                return res;
            })
            .catch(err => {
                return err;
            })
            return res;
        }
    }

    export  const  getCuotas = () => {
        return dispatch => {  
        //RUTA PRIVADA NECESITA TOKEN 
            const userData =  JSON.parse(localStorage.getItem('userData')); 
            const res = axios.get(path+`/configs`, {
                headers: { Authorization: "Bearer " + userData.token }})
            .then(res => {
                return res;
            })
            .catch(err => {
                return err;
            })
            return res;
        }
    }

    export  const  setCuotas = (Cuotas) => {
        //RUTA PRIVADA NECESITA TOKEN 
            const userData =  JSON.parse(localStorage.getItem('userData')); 
            return dispatch => {     
                const Promi = axios.put(path+`/configs/1`, Cuotas, {
                    headers: { Authorization: "Bearer " + userData.token }})
                .then(res => {
                    dispatch(getTna())
                    return Promise.resolve();
                })
                .catch(err => {
                    dispatch(reciveError(true))
                    return Promise.reject()
                })
                return Promi;                                                   
        }
    }

    export  const  setMontos = (Cuotas) => {
        //RUTA PRIVADA NECESITA TOKEN 
        console.log(Cuotas)
            const userData =  JSON.parse(localStorage.getItem('userData')); 
            return dispatch => {     
                const Promi = axios.put(path+`/configs/2`, Cuotas, {
                    headers: { Authorization: "Bearer " + userData.token }})
                .then(res => {
                    dispatch(getTna())
                    return Promise.resolve();
                })
                .catch(err => {
                    dispatch(reciveError(true))
                    return Promise.reject()
                })
                return Promi;                                                   
        }
    }

    export  const  getUserImg = (id) => dispatch => { 
    //RUTA PRIVADA NECESITA TOKEN   
        const userData =  JSON.parse(localStorage.getItem('userData'));          
        axios.get(path+`/users/${id}`, {
            headers: { Authorization: "Bearer " + userData.token }})
        .then(res => {                       
            dispatch(reciveImg(res.data.user.dniImages))
        })
        .catch(err => console.log(err))
    }

    export  const  getImg = (imgName) => {
    //RUTA PRIVADA NECESITA TOKEN 
        //console.log('ACTION ',imgName)
        return dispatch => {   
            const userData =  JSON.parse(localStorage.getItem('userData')); 
            const img = axios.get(path+`/users/getImage/${imgName}`, 
            { headers: { Authorization: "Bearer " + userData.token }, responseType: 'blob'})
            .then(res => { 
                console.log('actio')
                console.log(res)     
                return res;                
            })
            .catch(err => console.log(err))
        return img
        }
    }

    export  const  getTaxes = () => dispatch => {
    //RUTA PRIVADA NECESITA TOKEN 
        const userData =  JSON.parse(localStorage.getItem('userData'));         
        axios.get(path+`/taxes`, {
            headers: { Authorization: "Bearer " + userData.token }})
        .then(res => {              
            dispatch(reciveGetTaxes(res.data.taxList))
        })
        .catch(err => console.log(err))
    }

    export  const  getCapital = () => dispatch => {
    //RUTA PRIVADA NECESITA TOKEN  
        const userData =  JSON.parse(localStorage.getItem('userData'));      
        axios.get(path+`/capitals`, {
            headers: { Authorization: "Bearer " + userData.token }})
        .then(res => { 
            dispatch(reciveGetCapitals(res.data.debtorsList[0].amount))
        })
        .catch(err => console.log(err))
    }

    export  const  getCaja = () => dispatch => {      
    //RUTA PRIVADA NECESITA TOKEN    
        const userData =  JSON.parse(localStorage.getItem('userData')); 
        axios.get(path+`/cashboxes`, {
            headers: { Authorization: "Bearer " + userData.token }})
        .then(res => { 
            dispatch(reciveGetCaja(res.data.cashboxList[0].amount))
        })
        .catch(err => console.log(err))
    }

    export  const  getCirculacion = () => dispatch => {      
        //RUTA PRIVADA NECESITA TOKEN    
        console.log('ciruclaicon')
            const userData =  JSON.parse(localStorage.getItem('userData')); 
            axios.get(path+`/payments/paymentOnTraffic`, {
                headers: { Authorization: "Bearer " + userData.token }})
            .then(res => { 
                dispatch(reciveGetCirculacion(res.data.paymentsInTraffic[0].InTraffic))
                console.log('EN TRAFICO ',res)
            })
            .catch(err => console.log(err))
    }

    export  const  getIValoresMes = () => dispatch => {      
        //RUTA PRIVADA NECESITA TOKEN    
        console.log('OBTENIENDO VALORES DEL MES')
            const userData =  JSON.parse(localStorage.getItem('userData')); 
            axios.get(path+`/loans/getMonthValues`, {
                headers: { Authorization: "Bearer " + userData.token }})
            .then(res => { 
                console.log('VALORES DEL MES', res.data.objResponse)
                dispatch(reciveGetValoresMes(res.data.objResponse))
            })
            .catch(err => console.log(err))
    }

    export  const getLoanPayments =  (id, date) => async  dispatch => { 
        //RUTA PRIVADA NECESITA TOKEN \
        const userData =  JSON.parse(localStorage.getItem('userData')); 
        const Promi = await axios.get(path+`/payments/paymentByLoan/${id}`, {
            headers: { Authorization: "Bearer " + userData.token }, 
            params: { date }}, 
        )
        .then(res => { 
            let newPayment = {...res.data.paymentsList, owedIva: res.data.owedIva}
            dispatch(reciveGetCuota(newPayment))
            return Promise.resolve();
        })
        .catch(err => {
            return Promise.reject()
        })
        return Promi;
    }

    export const setTna =  (newTna) => {
    //RUTA PRIVADA NECESITA TOKEN 
        const userData =  JSON.parse(localStorage.getItem('userData')); 
        return dispatch => {     
            const Promi = axios.put(path+`/tnas/${newTna.id}`, newTna, {
                headers: { Authorization: "Bearer " + userData.token }})
            .then(res => {
                dispatch(getTna())
                return Promise.resolve();
            })
            .catch(err => {
                dispatch(reciveError(true))
                return Promise.reject()
            })
            return Promi;                                                   
        }
    }

    export const setConfig =  (newConfig) => {
        //RUTA PRIVADA NECESITA TOKEN 
            const userData =  JSON.parse(localStorage.getItem('userData')); 
            return dispatch => {                
                const Promi = axios.put(path+`/config/${newConfig.id}`, newConfig, {
                    headers: { Authorization: "Bearer " + userData.token }})
                .then(res =>{ 
                    return Promise.resolve();    
                })
                .catch(err => {
                    dispatch(reciveError(true))
                    return Promise.reject()
                }) 
                return Promi;                                                         
            }
    }

    export const setMora =  (newMora) => {
    //RUTA PRIVADA NECESITA TOKEN 
        const userData =  JSON.parse(localStorage.getItem('userData')); 
        return dispatch => {                
            const Promi = axios.put(path+`/owed/${newMora.id}`, newMora, {
                headers: { Authorization: "Bearer " + userData.token }})
            .then(res =>{ 
                dispatch(getMora());
                return Promise.resolve();    
            })
            .catch(err => {
                dispatch(reciveError(true))
                return Promise.reject()
            }) 
            return Promi;                                                         
        }
    }

    export const setIva =  (newIva) => {
    //RUTA PRIVADA NECESITA TOKEN 
        const userData =  JSON.parse(localStorage.getItem('userData')); 
        return dispatch => {                
            const Promi = axios.put(path+`/iva/${newIva.id}`, newIva, {
                headers: { Authorization: "Bearer " + userData.token }})
            .then(res =>{ 
                dispatch(getIva());
                return Promise.resolve();    
            })
            .catch(err => {
                dispatch(reciveError(true))
                return Promise.reject()
            }) 
            return Promi;                                                         
        }
    }

    export const setPayments =  (payload) => dispatch => {   
    //RUTA PRIVADA NECESITA TOKEN 
        const userData =  JSON.parse(localStorage.getItem('userData')); 
        axios.put(path+`/payments/${payload.payment_id}`, payload, {
            headers: { Authorization: "Bearer " + userData.token }})
        .then(res =>{ 
            //console.log(res)
        })
        .catch(err => {
            //console.log(err)
        }) 
    }
    
    export const updateLoan = (payload, callback)  => dispatch =>  {
    //RUTA PRIVADA NECESITA TOKEN 
        const userData =  JSON.parse(localStorage.getItem('userData')); 
        axios.put(path+`/loans/${payload.id}`, payload, {
            headers: { Authorization: "Bearer " + userData.token }})
        .then(res =>{
            console.log('UPDATE CORRECTO')
            callback();
        })
        .catch(err => {
            dispatch(reciveError(true));
        })
    }

    export const updateTax = (payload)  => {
    //RUTA PRIVADA NECESITA TOKEN 
        const userData =  JSON.parse(localStorage.getItem('userData')); 
        return dispatch =>  {
            const Promi = axios.put(path+`/taxes/${payload.id}`, payload, {
                headers: { Authorization: "Bearer " + userData.token }})
            .then(res =>{
                dispatch(getTaxes());
                return Promise.resolve();                      
            })
            .catch(err => {
                dispatch(reciveError(true))
                return Promise.reject()
            })
            return Promi;
        }       
    }

    export const AddTax = (payload)  => {
    //RUTA PRIVADA NECESITA TOKEN 
        const userData = localStorage.getItem('userData');
        return dispatch =>  {
            const Promi = axios.post(path+`/taxes`, payload, {
                headers: { Authorization: "Bearer " + userData.token }})
            .then(res => {
                dispatch(getTaxes()) 
                return Promise.resolve(); 
            })
            .catch(err => {
                dispatch(reciveError(true))
                return Promise.reject()
            })
        return Promi;
        }
    }

    export const AddCapital = (capital)=> dispatch => {
    //RUTA PRIVADA NECESITA TOKEN 
    const userData =  JSON.parse(localStorage.getItem('userData')); 
    const date = new Date();
    const capitalInt = parseInt(capital);
    const payload = { amount: 0 , date: date, registered_amount: capitalInt} 
        axios.post(path+`/capitals`, payload, {
            headers: { Authorization: "Bearer " + userData.token }})
            .then(res => {
                dispatch(getCapital());
                dispatch(getCaja());
            })
            .catch(err => {
                dispatch(reciveError(true))
            })
    }

    export const removeCapital = (capital)=> dispatch => {
    //RUTA PRIVADA NECESITA TOKEN 
        const userData =  JSON.parse(localStorage.getItem('userData')); 
        const date = new Date();
        const capitalInt = -(parseInt(capital));
        const payload = { amount: 0 , date: date, registered_amount: capitalInt} 
            axios.post(path+`/capitals`, payload, {
                headers: { Authorization: "Bearer " + userData.token }})
                .then(res => {
                    dispatch(getCapital());
                    dispatch(getCaja());
                })
                .catch(err => {
                    dispatch(reciveError(true))
                })
    }

    export const setStateLoan =  (loan, callback) =>{ 
     return dispatch => {   
    //RUTA PRIVADA NECESITA TOKEN   
        const userData =  JSON.parse(localStorage.getItem('userData'));  
        axios.put(path+`/loans/${loan.id}`, loan, { headers: { Authorization: "Bearer " + userData.token }})
        .then(res => {
                callback();
                return res;
            })
        .catch(err => {
                dispatch(reciveError(true));
            })
        }
    }

    export const setErrorClear =  (loan) => dispatch => { 
    //SIN LLAMADAS A LA API            
       dispatch(reciveError(false))
    }

    export const setErrorMessage =  (mensaje) => dispatch => {  
    //SIN LLAMADAS A LA API   
        dispatch(reciveMesagge(mensaje));
    }

    export const setCierreMes =  () => { return dispatch => {   
        //RUTA PUBLICA MODIFICAR ESTO!
        //const userData =  JSON.parse(localStorage.getItem('userData'));  
            let res = axios.get(path+`/loans/closeMonth`)
            .then(res => {
                    return (res.data);
            })
            .catch(err => {
                dispatch(reciveError(true));
            })
            return res;
        }
    }
    
    export const cleanPayment = () => dispatch => {
    //SIN LLAMADAS A LA API   
        dispatch(reciveGetCuota(undefined));
    }

    export const autoLogin = () => {
        return dispatch => {
            let autoLogin = JSON.parse(localStorage.getItem('userData')); 
            if(autoLogin !== null ){
                dispatch(reciveLogin(autoLogin));  
            }
        }
    }