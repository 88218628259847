import {useState} from 'react';
import * as yup from 'yup';

let schema = yup.object().shape({
    nombre: yup.string()
      .min(2, 'Demasiado corto!')
      .max(20, 'Demasiado largo!'),
    apellido: yup.string()
      .min(2, 'Demasiado corto!')
      .max(20, 'Demasiado largo!'),
    direccion: yup.string()
      .min(2, 'Demasiado corto!')
      .max(30, 'Demasiado largo!'),
    telefono: yup.number().typeError("Solo numeros seran permitidos")
      .min(5, 'Demasiado corto!'),
    email: yup.string()
      .email('Invalid email'),
    dni: yup.number().typeError("Solo numeros seran permitidos")
      .min(8, 'Demasiado corto!'),
    valores: yup.number().typeError("Solo numeros seran permitidos")
      .positive(),
  });

const useForm = (initialValues, initialErrors) => {
  const [inputs, setInputs] = useState(initialValues);
  const [inputsErrors, setInputsErros] = useState(initialErrors);
  
  const handleValidation = async ( inputFields, name) => {
    if(await schema.isValid(inputFields)){
        let obj = ({...inputsErrors});
        obj[name] = '';
        setInputsErros(obj);
    }else{
      let obj = ({...inputsErrors}); //objeto errores 
     try{
         await schema.validateSync(inputFields, { abortEarly: false }) 
     }
     catch (err){   
      if(inputFields[name].length === 0){ //esto se podria sacar y usar la funcionalidad que da FormControl de material ui que verifica requerido
        obj[name] = 'Requerido';
        setInputsErros(obj);
      }else{
        obj[name] = err.message;
        setInputsErros(obj);
      }
     }                             
    }
  }

  const handleInputChange =  (event) => {
    event.persist();
    setInputs(inputs => ({...inputs, [event.target.name]: event.target.value}));
    handleValidation({[event.target.name]: event.target.value},[event.target.name]);
  }

  return {
    handleInputChange,
    inputs,
    inputsErrors
  };
}

export default useForm;