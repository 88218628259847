import React, { useEffect } from "react";
import Box from '@material-ui/core/Box';
import "./styles.scss";
import {  NavLink} from 'react-router-dom';
import { CustomSlider } from '../material/custom-slider/CustomSlider';
import { CustomButtom } from  '../material/custom-button/CustomButtom';
import {setChange} from '../../store/actions/action';
import {useSelector, useDispatch} from 'react-redux';
import { useState } from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '80%',
    alignItems:'center',
  },
  
}));

const valueLabelFormat= (value) => {
  return (`$${value}`);
}
const CalculadoraPrestamo = () => {
  const [ label, setLabel ] = useState(0)
  
  const dispatch = useDispatch()
  const tna = useSelector(state => state.user.tna);
  const cuotas = useSelector(state => state.user.cuotas);
  const credito = useSelector(state => state.user.credito);
  const cuotaMin = useSelector(state => state.user.cuotaMin);
  const cuotaMax = useSelector(state => state.user.cuotaMax);
  const creditoMin = useSelector(state => state.user.creditoMin);
  const creditoMax = useSelector(state => state.user.creditoMax);

  useEffect(() => {
    if (tna !== null){
      const select = tna.find(array => array.fee === cuotas);
      const feeTna = (((select.percentage)/12)/100);
      const cuotaPura = credito * ((Math.pow(1+feeTna,cuotas)*feeTna)/(Math.pow(1+feeTna,cuotas)-1));
      const interesCuota = ( credito * feeTna );
      const capital = ( cuotaPura - interesCuota );
      const ivaCuota = interesCuota * 0.21; 
      setLabel((ivaCuota+capital+interesCuota).toFixed(2));
    }
  }, [cuotas, credito,tna])
 
    return (
      <div className="box-calculator ">
        <div className="container-all-calculator ">
          <div className="container-calculator">
            <p className="title-calculator">Calculadora de préstamo</p>
            <Box mx={3}>
            <form className="form-calculator">
              <CustomSlider 
                aria-label="money slider"
                value={credito}
                max={creditoMax}
                min={creditoMin}
                valueLabelDisplay='on'
                valueLabelFormat={valueLabelFormat}
                onChange={(e, data) => dispatch(setChange('credito',data))} 
                step={500}   
                marks={[{value: creditoMin, label: `${creditoMin}`},{value: creditoMax, label: `${creditoMax}`}]}
              />
               <CustomSlider
                aria-label="dues slider"
                value={cuotas}
                max={cuotaMax}
                min={cuotaMin}
                valueLabelDisplay='on'
                onChange={(e, data) => dispatch(setChange('cuotas',data))} 
                marks={[{value: cuotaMin, label: `${cuotaMin}`},{value: cuotaMax, label: `${cuotaMax}` }]}
              />
              <p className="value-aprox-cuota">
                Valor aproximado de tu cuota ${label}
              </p>
              <NavLink
                to='/generador-de-solicitud'
                style={{ textDecoration: 'none'}}
              >
                <CustomButtom  
                  variant="contained" 
                  color="primary" 
                >
                  Solicitar   
                </CustomButtom>
              </NavLink>  
            </form>
            </Box>
          </div>
        </div>
      </div>
    );
  }

export default CalculadoraPrestamo;
