
export const calcCredit = ( tna , cantCuotas , credito) => {
    //CALCULO CUOTA
    let cftSinIva = saveLoanPayments(tna , cantCuotas , credito)
    
    const feeTna = ((tna/12)/100); 
    const cuotaPura = credito * ((Math.pow(1+feeTna,cantCuotas)*feeTna)/(Math.pow(1+feeTna,cantCuotas)-1));
    const interesCuota = ( credito * feeTna );
    const capital = ( cuotaPura - interesCuota );
    const ivaCuota = interesCuota * 0.21; 
    const valorCuota = (ivaCuota+capital+interesCuota).toFixed(2);
    const devTotal = (valorCuota * cantCuotas).toFixed(2);
    let cftConIva = ((devTotal/credito)*100).toFixed(2);
    //CALCULO TEA
    const tea = (((Math.pow(1+((tna/12)/100),12))-1)*100).toFixed(2);

    const res = {
        valorCuota,
        devTotal,
        tea,
        cftSinIva,
        cftConIva,
    }

    return res
}


function calculatePurePayment(capital, interest, payments) {
  const purePayment = capital * ((Math.pow((1 + interest), payments) * interest)
  / ((Math.pow((1 + interest), payments) - 1)));
  return purePayment;
}

function calculatePaymentInterest(capital, interest) {
  const paymentInterest = capital * interest;
  return paymentInterest;
}

// CON INTERES MENSUAL
// eslint-disable-next-line no-unused-vars
function saveLoanPayments(tna , cantCuotas , credito) {
  const interest = (tna/100)/12;
  let capital = credito;
  const payments = cantCuotas;
  const purePayment = calculatePurePayment(capital, interest, payments);
  //console.log(`Cuota pura: ${purePayment.toFixed(2)}`);
  //console.log(`Capital inicial: ${capital.toFixed(2)}`);
  let totalPayment = 0;
  let TotalAdmExpenses = 0;
  let totalCapital = 0;
  let totalInteres = 0;
  for (let i = 1; i <= payments; i += 1) {
    const paymentInterest = calculatePaymentInterest(capital, interest);
    const paymentCapital = purePayment - paymentInterest;
    const paymentTax = paymentInterest * 0.21;
    let admExpenses = 0;
    let admExpensesTax = 0;
    if (i > 1) {
      admExpenses = paymentInterest + paymentCapital + paymentTax;
      admExpenses = (totalPayment - admExpenses) / 1.21;

      admExpensesTax = admExpenses * 0.21;
    }
    totalPayment = paymentInterest + paymentCapital + paymentTax + admExpenses + admExpensesTax;
    capital -= paymentCapital; // to limit decimals
    TotalAdmExpenses = admExpenses + TotalAdmExpenses;
    totalCapital = totalCapital + paymentCapital;
    totalInteres = totalInteres + paymentTax;
    }

    //console.log('TOTAL ADMIN' ,TotalAdmExpenses);
    //console.log('TOTAL CAP' ,credito);
    //console.log('TOTAL Inte' ,totalInteres);
    const cft = (TotalAdmExpenses+credito+totalInteres)*100/credito
    return (cft.toFixed(2))
}