import React from "react";
import { Route } from "react-router-dom";
import PropTypes from 'prop-types';
import "../../App.css";
import NavBar from "../navbar";
import Header from "../header";
import Footer from "../footer";
import "./styles.css";
import {Redirect} from 'react-router-dom';
import Terms from '../Terms';

const DefaultLayout = ({ children, ...rest }) => {
 
  return (
    <div className="App">
      <Header />
      <NavBar />
        <div className="main">{children}</div>
      <Terms />
      <Footer />
    </div>
  );
};

const DefaultLayoutRoute = props =>{ 
  const {component: Component,  layout:Layout, isLogged, ...rest } = props;
  if(isLogged === false && (rest.path === `/admin-panel/prestamos` || rest.path === `/admin-panel/formulas`|| rest.path === `/admin-panel/estadisticas`)){
    const redirect =  <Redirect to="/"/>
    return(redirect)
  }else if(isLogged === true && (rest.path === `/admin-panel/prestamos` || rest.path === `/admin-panel/formulas`|| rest.path === `/admin-panel/estadisticas`)){
    return (
      <Route
        {...rest}
        render={matchProps => (        
            <Component {...matchProps} >
                <Layout/>
            </Component>         
        )}
      />
    );
  }else if(isLogged === false && rest.path === `/login`){
    return (
      <Route
        {...rest}
        render={matchProps => (        
            <Component {...matchProps} />                                    
        )}
      />
    );
  }else{
    return (
        <Route
          {...rest}
          render={({ matchProps }) => (
            <DefaultLayout {...matchProps} {...rest}>
              <Component />
            </DefaultLayout>
          )}
        />          
        );
  }    
}

DefaultLayoutRoute.propTypes = {
  component: PropTypes.any.isRequired,
  path: PropTypes.string
};
export default DefaultLayoutRoute;

