import * as actionTypes from '../actions/adminActions';

const initialState = {
  isLogged : false,
  token: null,
  userId: null,
  tna: undefined,
  taxes:undefined,
  loadingTna:false,
  arrayImg: [],
  error: false,
  mensaje:false,
  capital: 0,
  caja:0,
  circulacion:0,
  iva:0,
  gastos:0,
  intereses:0,
  pago:0,
  payment:undefined,
  isLogin: false,
}

const admin = (state = initialState, action ) => {
    switch (action.type) {
        case actionTypes.LOGOUT_ADMIN:          
          return {
            ...state,
            isLogged : false,
          };

        case actionTypes.LOGIN_ADMIN:  
          return {
            ...state,
            isLogged : true,
            token: action.user.token,
            userId: action.user.userId,
          };

          case actionTypes.ISLOGIN:  
          return {
            ...state,
            isLogin : action.loading,
          };
        
          case actionTypes.GET_TNA:
          return{
            ...state,
            tna : action.tna,
          }

          case actionTypes.GET_CIRCULACION:
          return{
            ...state,
            circulacion : action.payments,
          }

          case actionTypes.GET_VALORESMES:
          return{
            ...state,
            iva: action.obj.iva,
            gastos: action.obj.admExpenses,
            intereses: action.obj.Fee,
          }

          case actionTypes.GET_CAPITAL:
            return{
              ...state,
              capital : action.monto,
            }
          
          case actionTypes.GET_PAYMENT:
            return{
              ...state,
              payment : action.payment,
          }

          case actionTypes.GET_CAJA:
            return{
              ...state,
              caja : action.monto,
          }

          case actionTypes.GET_TAXES:
          return{
            ...state,
            taxes : action.taxes,
          }

          case actionTypes.SET_PAGO:
            return{
              ...state,
              pago : action.pago,
            }

          case actionTypes.GET_IMG:
          return{
            ...state,
            arrayImg : action.array,
          }

          case actionTypes.SET_SEND_ERROR:
          return{
            ...state,
            error : action.err,
          }

          case actionTypes.SET_MENSAJE:
          return{
            ...state,
            mensaje : action.mensaje,
          }
          
        default:
          return state;
    }
};
    
export default admin;
