import React, {useState, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import {CustomButtom} from '../../material/custom-button/CustomButtom';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useDispatch, useSelector} from 'react-redux';
import {setChange , addFile, deleteFile } from '../../../store/actions/action';
import * as yup from 'yup';
import Snackbar from '@material-ui/core/Snackbar';
import Frontal from '../../../img/dni-adelante.svg';
import Posterior from '../../../img/dni-atras.svg';
import Selfie from '../../../img/dni-selfie.svg';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

const useStyles = makeStyles({
  input: {
    display: 'none',
  },
});

const UploadPhoto= (props) => {
  const [ errCbu, setError] = useState(null);
  const [ errCbuMen , setCbuErrorMen] = useState('');
  const [ Alert1, setAlert1 ] = useState(false);
  const [ Alert2, setAlert2 ] = useState(false);
  const [ Alert3, setAlert3] = useState(false);
  const [ openErr, setOpenErr] = useState(false);
  const [ openDelete, setOpenDelete ] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const cbu = useSelector(state => state.user.cbu);
  const fotos = useSelector(state => state.user.fotos);
  
  useEffect(() => {
    if(fotos[0].primera !== null){setAlert1(true);}
    if(fotos[1].segunda !== null){setAlert2(true);}
    if(fotos[2].tercera !== null){setAlert3(true);}
  }, [fotos]);
    
  let schema = yup.object().shape({
    cbu: yup.number().nullable().default(null)
  });

  const handleChangeCbu = async (e) => {
    dispatch(setChange('cbu',e.target.value));
  }

  const onChangeHandler = (e) => {
    //en principio no permite subir mas de un archivo, testear bien esto    
    if(e.target.files.length === 1) {      
      dispatch(addFile(e.target.files[0], e.target.id));    
      switch (e.target.id) {       
        case 'primera': {          
          setAlert1(true);
          break
        }        
        case 'segunda': {
          setAlert2(true);
          break;
        }
        case 'tercera': {
          setAlert3(true)
          break;
        }
      default: {
        break;
      }
    }
  }
}

  const handleDeleteFile = (id) => {
    switch (id) {       
      case 'primera': {          
        setAlert1(false);
        setOpenDelete(true);
        dispatch(deleteFile(0,'primera')); 
        break
      }        
      case 'segunda': {
        setAlert2(false);
        setOpenDelete(true);
        dispatch(deleteFile(1,'segunda')); 
        break;
      }
      case 'tercera': {
        setAlert3(false)
        setOpenDelete(true);
        dispatch(deleteFile(2,'tercera')); 
        break;
      }
      default: {
        break;
      }
    }
  }

  const handleSubmit = async () => {
    if(await schema.isValid({cbu : cbu}) || cbu === ''){
      props.handleNext();
    }else{
      setError(true);
      setCbuErrorMen('Ingrese cbu valido');
      handleShowError();
    }    
  }

  const handleShowError = () => {
    setOpenErr(true);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenErr(false);
    setOpenDelete(false);
  };

  return (
    <div>
      <Box textAlign='left'>
       <Snackbar open={openErr} autoHideDuration={3000} onClose={handleCloseError}>
        <Alert  elevation={6} variant="filled" onClose={handleCloseError} severity="error">
          Complete los campos requeridos
        </Alert>
      </Snackbar>

      <Snackbar open={openDelete} autoHideDuration={3000} onClose={handleCloseError}>
        <Alert  elevation={6} variant="filled" onClose={handleCloseError} severity="info">
          Archivo eliminado.
        </Alert>
      </Snackbar>

      <Grid container alignContent='flex-start'>
          <Grid item xs={12} >
            <Typography 
                gutterBottom 
                variant="h4"
              >
                Los datos de este paso no son obligatorios pero acelerarán el proceso!
            </Typography>
            <TextField            
                label="Ingresa tu CBU"
                id="cbu"
                error={errCbu}
                value={cbu}
                onBlur={e => handleChangeCbu(e)}
                margin="normal"
                onChange={e => handleChangeCbu(e)}
                helperText={errCbuMen}
              />
          </Grid>          
     
          <Grid item xs={12}>
            <Box  py={3}>
              <Typography 
                gutterBottom
                variant="h2"
              >
                Carga las fotos de tu D.N.I
              </Typography>
              
            </Box>
          </Grid>
            
          <Grid item xs={12}> 
            <Box 
              display='flex' 
              flexDirection="row"  
              alignItems="center"
            >
              <Box pb={2}>
                <Typography variant="h3">Subir foto del lado de adelante.</Typography>
              </Box>
              <Box>
                <input                     
                    accept="image/*" 
                    className={classes.input} 
                    id="primera" 
                    type="file"
                    onChange={onChangeHandler} 
                  />
                  <label htmlFor="primera">
                    <IconButton 
                      color="primary" 
                      aria-label="upload picture" 
                      component="span"
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </label>    
              </Box>
            </Box>
          </Grid>

        <Grid item xs={12}>
          <Box width="50%"> 
              <img src={Frontal} height='150' width='150' alt='asd'/>  
              <Collapse in={Alert1}>
                <Alert severity="info" onClose={() => {handleDeleteFile('primera')}}>Carga correcta</Alert>
              </Collapse>         
          </Box>
        </Grid>    
      </Grid>    
    

      <div style={{ width: '100%' }}>
        <Box 
          display='flex' 
          flexDirection="row"  
          alignItems="center"
        >  
          <Box pb={2}>      
            <Typography variant="h3">Subir foto del lado de atras.</Typography>
          </Box>
          <Box> 
            <input 
              accept="image/*" 
              className={classes.input} 
              id="segunda" 
              type="file"
              onChange={onChangeHandler} 
            />
              <label htmlFor="segunda">
                <IconButton 
                  color="primary" 
                  aria-label="upload picture" 
                  component="span"
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </label> 
          </Box>                     
        </Box>
        <Box width="50%"> 
          <img src={Posterior} height='150' width='150' alt='asd'/>  
          <Collapse in={Alert2}>
            <Alert severity="info"  onClose={() => {handleDeleteFile('segunda')}}>Carga correcta.</Alert>          
          </Collapse>
        </Box>
      </div>     

       <div style={{ width: '100%' }}>
       <Box 
          display='flex' 
          flexDirection="row"  
          alignItems="center"
        >  
          <Box pb={2}>                                     
            <Typography variant="h3">Subir foto con el dni.</Typography> 
          </Box>  
          <Box>                      
          <input 
            accept="image/*" 
            className={classes.input} 
            id="tercera" 
            type="file" 
            onChange={onChangeHandler} 
          />
          <label htmlFor="tercera">
            <IconButton 
              color="primary" 
              aria-label="upload picture" 
              component="span"                      
            >
              <AddCircleOutlineIcon />
            </IconButton>
          </label>                 
        </Box>
      </Box>
      <Box width="50%">
        <img 
          src={Selfie} 
          height='150' 
          width='150' 
          alt='asd'
        />  
        <Collapse in={Alert3}>
          <Alert severity="info"  onClose={() => {handleDeleteFile('tercera')}}>Carga correcta.</Alert>          
        </Collapse>
      </Box>
      </div>
      <Grid item xs={12}>
        <Box 
          textAlign='center' 
          pt={5}
        >
          <CustomButtom
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
              Siguiente
          </CustomButtom>            
        </Box> 
      </Grid>   
      </Box> 
  </div>
  );
}

export default  UploadPhoto;


